import React from 'react';

import Brand from 'components/_base/Brand';
import FormSSOLogin from 'components/FormSSOLogin';

import './styles.scss';

const HeaderSSOLogin = () => {
  return (
    <header className="header-login">
      <div className="content-brand">
        <Brand white />
      </div>
      <div className="content-form-login">
        <FormSSOLogin />
      </div>
    </header>
  );
};

export default HeaderSSOLogin;
