/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faChalkboardTeacher,
} from '@fortawesome/free-solid-svg-icons';

import { GET_SITE_CONFIG } from 'services/SysConfig';
import './styles.scss';

const Contact = () => {
  const [download, setDownload] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await GET_SITE_CONFIG({ keyFilter: 'BRF_MANUAL_URL' });

      if (data.value != null && data.value.length > 0) {
        setDownload(data.value[0].value);
      }
    })();
  }, []);

  return (
    <div className="wrapper-atendimento">
      <div className="title">Contato</div>
      <div className="atendimento">
        <form>
          <div className="row ">
            <label className="col-md-4 text-md-right">
              Horário de atendimento
            </label>
            <span className="col-md-8 ">
              Segunda a sexta das 9:00 às 18:00h
            </span>
          </div>
          <div className="row">
            <label className="col-md-4 text-md-right">
              Telefone para contato
            </label>
            <span className="col-md-8">(11) 9 9811-0557</span>
          </div>
          <div className="row">
            <label className="col-md-4  text-md-right">
              E-mail para contato
            </label>
            <span className="col-md-8 ">tamires@multimaxi.com.br </span>
          </div>
          {/* <div className="row download">
            <div className="offset-md-4 col-md-8">
              <Button>
                <a
                  href={download}
                  target="_blank"
                  download="Manual.pdf"
                  className="col-md-6 col-sm-6 botao"
                  variant="primary"
                >
                  <FontAwesomeIcon icon={faDownload} />{' '}
                  <small>Baixar Manual do Sistema</small>
                </a>
              </Button>
              <Button>
                <Link to="/tutorial-sistema">
                  <FontAwesomeIcon icon={faChalkboardTeacher} />{' '}
                  <small>Tutorial do Sistema</small>
                </Link>
              </Button>
            </div>
          </div> */}
          <div className="d-flex justify-content-center download m-3">
            <div className="pr-2">
              <Button size="md">
                <a
                  href={download}
                  target="_blank"
                  download="Manual.pdf"
                  className="col-md-6 col-sm-6 botao"
                  variant="primary"
                >
                  <FontAwesomeIcon icon={faDownload} />{' '}
                  <small>Baixar Manual do Sistema</small>
                </a>
              </Button>
            </div>
            <div className="pl-2">
              <Button>
                <Link to="/tutorial-sistema">
                  <FontAwesomeIcon icon={faChalkboardTeacher} />{' '}
                  <small>Tutorial do Sistema</small>
                </Link>
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
