import api from 'api';

const PATH = '/CadastroUsuario';

const GET_USUARIOTIPOS_SELECTLIST = async () => {
  const result = await api.get(`${PATH}/get-usuariotipos-selectlist`);
  return result.data.value || [];
};

const GET_USUARIOSUPERIOR_SELECTLIST = async () => {
  const result = await api.get(`${PATH}/get-usuariosuperior-selectlist`);
  return result.data.value || [];
};

const GET_REGIONAIS_SELECTLIST = async () => {
  const result = await api.get(`${PATH}/get-regionais-selectlist`);
  return result.data.value || [];
};

const GET_USUARIOS_AUTOCADASTRADOS = async () => {
  const result = await api.get(`${PATH}/get-usuarios-autocadastrados`);
  return result.data.value || [];
};

const CADASTRAR = async (formData) => {
  const result = await api.post(`${PATH}/cadastrar`, formData);
  return result.data || {};
};

const ATUALIZAR_USUARIO = async (formData) => {
  const result = await api.post(`${PATH}/atualizar`, formData);
  return result.data || {};
};

const APROVAR_USUARIO = async (usuarioId) => {
  const result = await api.post(`${PATH}/aprovar`, { usuarioId });
  return result?.data?.value || false;
};

const REPROVAR_USUARIO = async (usuarioId) => {
  const result = await api.post(`${PATH}/reprovar`, { usuarioId });
  return result?.data?.value || false;
};

export {
  GET_USUARIOTIPOS_SELECTLIST,
  GET_USUARIOSUPERIOR_SELECTLIST,
  GET_REGIONAIS_SELECTLIST,
  GET_USUARIOS_AUTOCADASTRADOS,
  CADASTRAR,
  ATUALIZAR_USUARIO,
  APROVAR_USUARIO,
  REPROVAR_USUARIO,
};
