/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';

import { Context } from 'context/AuthContext';
import { GET_LIST_SOLICITACAO_ARQUIVO } from 'services/SolicitacaoFluxo';

import Lightbox from 'react-image-lightbox';

import 'react-image-lightbox/style.css';
import './styles.scss';
import axios from 'axios';
import api from 'api';

const SolicitacaoArquivos = (props) => {
  const { showLoader } = useContext(Context);

  const solicitacaoId = props.solicitacaoId || 0;
  const [solicitacaoArquivos, setSolicitacaoArquivos] = useState([]);
  const [lightboxStatus, setLightboxStatus] = useState({
    photoIndex: 0,
    isOpen: false,
    categoria: '',
  });

  useEffect(() => {
    if (solicitacaoId === 0) return;

    (async () => {
      showLoader(true);

      const responseArquivos = await GET_LIST_SOLICITACAO_ARQUIVO(
        solicitacaoId
      );

      setSolicitacaoArquivos(
        responseArquivos.value.map((item) => ({
          ...item,
          arquivosImagem: item.arquivos.filter(
            (arquivo) =>
              arquivo.arquivoUrl.match(/\.(gif|jpeg|jpg|png)/gi)?.shift() &&
              arquivo.tipo === 'AQV'
          ),
          arquivosVideo: item.arquivos.filter((arquivo) =>
            arquivo.arquivoUrl.match(/\.(mp4)/gi)?.shift()
          ),
          arquivosLink: item.arquivos.filter(
            (arquivo) => arquivo.tipo === 'LNK'
          ),
          arquivosOther: item.arquivos.filter(
            (arquivo) =>
              !arquivo.arquivoUrl.match(/\.(mp4|gif|jpeg|jpg|png|LNK)/gi) &&
              arquivo.tipo === 'AQV'
          ),
        }))
      );

      showLoader(false);
    })();
  }, [solicitacaoId]);

  function toDataURL(url) {
    showLoader(true);
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  }

  // async function forceDownload(url, tag = 'Download') {
  //   showLoader(true);
  //   const a = document.createElement('a');
  //   a.href = await toDataURL(url);
  //   a.download = fileName;
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  //   showLoader(false);
  // }

  const download = (url, index) => {
    var element = document.createElement('a');
    // var file = new Blob([url], { type: 'image/*' });
    var file = new Blob([url]);
    element.href = URL.createObjectURL(file);
    index = index + 1;
    element.download = solicitacaoId + '-imagem-' + index;
    element.click();
  };

  async function forceDownload(url, tag) {
    url = url.split('?')[0];

    var urlsplit = url.split('/');

    var fileName = tag + '_' + urlsplit[urlsplit.length - 1];

    var url = process.env.REACT_APP_API_ROOT + 'arquivo/Download?url=' + url;

    const a = document.createElement('a');
    a.href = await toDataURL(url);
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    showLoader(false);
  }

  return (
    <div className="solicitacao-fluxo-arquivos">
      {solicitacaoArquivos.map((item, index1) => {
        return (
          <div key={index1}>
            <h4 className="text-center">{item.categoria}</h4>
            <div className="row d-flex justify-content-md-center mb-3">
              {item.arquivosImagem.length > 0 &&
                item.arquivosImagem.map((arquivo, index2) => {
                  return (
                    <div className="col-md-6 d-flex justify-content-center align-items-center">
                      <div className="img-fluid text-center d-flex flex-column">
                        <img
                          src={arquivo.arquivoUrl}
                          className="img-fluid"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            document.body.style.position = 'fixed';
                            document.body.style.width = '100%';
                            setLightboxStatus({
                              photoIndex: index2,
                              isOpen: true,
                              categoria: item.categoria,
                            });
                          }}
                        />
                        <div
                          onClick={() =>
                            forceDownload(arquivo.arquivoUrl, index2)
                          }
                          className="btn btn-link"
                          target="_blank"
                          rel="noopener
                          noreferrer"
                          download="download"
                        >
                          {' '}
                          Download
                        </div>
                      </div>
                    </div>
                  );
                })}

              {item.arquivosVideo.length > 0 &&
                item.arquivosVideo.map((arquivo, index2) => {
                  return (
                    <div
                      key={index2}
                      className="col-md-12 d-flex justify-content-center align-items-center"
                    >
                      <video width="480" height="320" controls>
                        <source src={arquivo.arquivoUrl} type="video/mp4" />
                        Seu navegador não suporta reprodução de video.
                      </video>
                    </div>
                  );
                })}
              {item.arquivosLink.length > 0 &&
                item.arquivosLink.map((arquivo, index2) => {
                  return (
                    <div
                      key={index2}
                      className="col-md-12 d-flex justify-content-center align-items-center pt-4"
                    >
                      Link:{' '}
                      <a
                        href={arquivo.arquivoUrl}
                        target="_blank"
                        rel="noreferrer"
                        className="ml-1"
                      >
                        {arquivo.arquivoUrl}
                      </a>
                    </div>
                  );
                })}

              {item.arquivosOther.length > 0 &&
                item.arquivosOther.map((arquivo, index2) => {
                  return (
                    <div className="col-md-6 d-flex justify-content-center align-items-center">
                      <div className="img-fluid text-center">
                        <a
                          href={arquivo.arquivoUrl}
                          className="btn btn-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Download
                        </a>
                      </div>
                    </div>
                  );
                })}
            </div>
            {lightboxStatus.isOpen &&
              lightboxStatus.categoria === item.categoria && (
                <Lightbox
                  mainSrc={
                    item.arquivosImagem[lightboxStatus.photoIndex].arquivoUrl
                  }
                  nextSrc={
                    item.arquivosImagem[
                      (lightboxStatus.photoIndex + 1) %
                        item.arquivosImagem.length
                    ].arquivoUrl
                  }
                  prevSrc={
                    item.arquivosImagem[
                      (lightboxStatus.photoIndex +
                        item.arquivosImagem.length -
                        1) %
                        item.arquivosImagem.length
                    ].arquivoUrl
                  }
                  onCloseRequest={() => {
                    setLightboxStatus({
                      ...lightboxStatus,
                      isOpen: false,
                      categoria: '',
                    });
                    document.body.style.position = 'static';
                    document.body.style.width = 'auto';
                  }}
                  onMovePrevRequest={() =>
                    setLightboxStatus({
                      ...lightboxStatus,
                      photoIndex:
                        (lightboxStatus.photoIndex +
                          item.arquivosImagem.length -
                          1) %
                        item.arquivosImagem.length,
                    })
                  }
                  onMoveNextRequest={() =>
                    setLightboxStatus({
                      ...lightboxStatus,
                      photoIndex:
                        (lightboxStatus.photoIndex + 1) %
                        item.arquivosImagem.length,
                    })
                  }
                />
              )}
          </div>
        );
      })}
    </div>
  );
};

export default SolicitacaoArquivos;
