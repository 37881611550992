/* eslint-disable */
import React, { useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { FaChevronLeft, FaTelegramPlane } from 'react-icons/fa';

import history from 'routes/history';
import api from 'api';
import { ADD_PLANO_ENVIO } from 'services/OnOff';
import { Context } from 'context/AuthContext';

const ResumoEnvio = (props) => {
  const { onChangeTab, formData } = props;
  const [erro, setErro] = useState({
    display: false,
    text: '',
  });

  const { showLoader } = useContext(Context);

  function goToPrevPage() {
    onChangeTab('upload');
  }

  async function goToNextPage() {
    showLoader(true);

    let arquivosSomenteUrl = formData.onOffPlanoEnvioArquivos.map((item) => {
      return {
        urlArquivo: item.urlArquivo,
      };
    });
    let params = {
      onOffRedeId: formData.onOffRedeId,
      tipoPlano: formData.tipoPlano,
      mesReferencia: formData.mesReferencia,
      onOffMaterialComunicacaoId: formData.onOffMaterialComunicacaoId,
      subTitulo: formData.subTitulo,
      veiculacao: formData.veiculacao,
      observacao: formData.observacao,
      onOffPlanoEnvioArquivos: arquivosSomenteUrl,
    };

    const data = await ADD_PLANO_ENVIO(params);

    showLoader(false);

    if (data && data.code == 200 && data.value) {
      history.push(`/on-off-midias-enviadas/`);
    } else {
      data &&
        data.errors &&
        setErro({
          display: true,
          text: data.errors[0],
        });
    }
  }

  return (
    <div>
      <h1 className="onoff-title">RESUMO DE ENVIO PLANO ON&OFF</h1>
      <div className="d-flex justify-content-center">
        <div className="onoff-content">
          <div>
            {erro.display === true && (
              <div class="alert alert-danger" role="alert">
                {erro.text}
              </div>
            )}
            <div className="table-label">Informações</div>
            <table className="table table-striped table-resumo">
              <tbody className="resumo-tbody">
                <tr>
                  <td>
                    <b>Usuário</b>
                  </td>
                  <td>{formData.user.nome}</td>
                </tr>
                <tr>
                  <td>
                    <b>Regional</b>
                  </td>
                  <td>{(formData.rede.regional.regionalSuperior?.nome ?? formData.rede.regional?.nome) ?? "Sem regional"}</td>
                </tr>
                <tr>
                  <td>
                    <b>Rede</b>
                  </td>
                  <td>{formData.rede.nome}</td>
                </tr>
                <tr>
                  <td>
                    <b>Bandeira</b>
                  </td>
                  <td>{formData.rede.bandeira}</td>
                </tr>
                <tr>
                  <td>
                    <b>Canal da Rede</b>
                  </td>
                  <td>{formData.rede.canal.canalSuperior.nome}</td>
                </tr>
                <tr>
                  <td>
                    <b>Subcanal da Rede</b>
                  </td>
                  <td>{formData.rede.canal.nome}</td>
                </tr>
                <tr>
                  <td>
                    <b>Plano</b>
                  </td>
                  <td>{formData.tipoPlano}</td>
                </tr>
                <tr>
                  <td>
                    <b>Tipo de Material</b>
                  </td>
                  <td>{formData.material}</td>
                </tr>
                <tr>
                  <td>
                    <b>Subtítulo</b>
                  </td>
                  <td>{formData.subTitulo}</td>
                </tr>
                <tr>
                  <td>
                    <b>Período de Veiculação</b>
                  </td>
                  <td>{formData.veiculacao===1 ? 'Primeira Quinzena' : 'Segunda Quinzena'}</td>
                </tr>
                <tr>
                  <td>
                    <b>Observação</b>
                  </td>
                  <td>{formData.observacao}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <UploadedImages />
          <NavButtons />
        </div>
      </div>
    </div>
  );

  function UploadedImages() {
    return (
      <div className="text-center">
        <div className="images-label">IMAGENS</div>
        {formData.onOffPlanoEnvioArquivos &&
          formData.onOffPlanoEnvioArquivos.map(function (item, index) {
            return (
              <img
                className="onoff-img"
                key={index}
                id={'image-' + index}
                src={item.urlArquivo}
                alt=""
              />
            );
          })}
      </div>
    );
  }

  function NavButtons() {
    return (
      <div>
        <Button
          className="onoff-button onoff-left"
          onClick={() => goToPrevPage()}
        >
          <FaChevronLeft size="28" /> VOLTAR{' '}
        </Button>
        <Button
          className="onoff-button onoff-right"
          onClick={() => goToNextPage()}
        >
          {' '}
          <FaTelegramPlane size="28" />
          &nbsp;&nbsp; ENVIAR
        </Button>
      </div>
    );
  }
};

export default ResumoEnvio;
