import React, { createContext } from 'react';
import useAuth from './Hooks/useAuth';
import materialComunicacao from './Hooks/materialComunicacao';
import etapa from './Hooks/etapa';
import regional from './Hooks/regional';
import motivo from './Hooks/motivo';

const Context = createContext();

const AuthProvider = ({ children }) => {
  const {
    showLoader,
    handleLogin,
    handleSSOLogin,
    handleLogout,
    handleSSOLogout,
    userData,
    setUserData,
    adminLoginAs,
    loginWithToken,
    aprovaUsuario,
  } = useAuth();

  const { getMaterialComunicacaoSelectList } = materialComunicacao();

  const { getEtapaSelectList } = etapa();

  const { getRegionalSelectListFilhas } = regional();

  const { getMotivoSelectList } = motivo();

  return (
    <Context.Provider
      value={{
        showLoader,
        handleLogin,
        handleSSOLogin,
        handleLogout,
        handleSSOLogout,
        getMaterialComunicacaoSelectList,
        getEtapaSelectList,
        getRegionalSelectListFilhas,
        getMotivoSelectList,
        userData,
        setUserData,
        adminLoginAs,
        loginWithToken,
        aprovaUsuario,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { Context, AuthProvider };
