import React, { useContext, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import { Context } from 'context/AuthContext';

const AdminLogar = () => {
  const { adminLoginAs } = useContext(Context);
  const history = useHistory();
  const params = useParams();
  const search = new URLSearchParams(useLocation().search);

  const redirect = search.get('urlReturn') ? search.get('urlReturn') : '/';
  const usuarioId = params.usuarioId || 0;

  useEffect(() => {
    if (usuarioId > 0)
      (async () => {
        await adminLoginAs(usuarioId, redirect);
      })();
    else history.push('/');
  }, []);

  return <></>;
};

export default AdminLogar;
