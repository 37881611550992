/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import {
  FaFileUpload,
  FaChevronRight,
  FaChevronLeft,
  FaPlusCircle,
} from 'react-icons/fa';

import api from 'api';
import {
  GET_SELECT_LIST_MATERIAL_COMUNICACAO,
  UPLOAD_ON_OFF_FILE,
} from 'services/OnOff';
import { DELETE_ONOFF_FILE } from 'services/OnOff';
import LoaderAction from 'store/modules/loader/actions';
import InputFileCustom from 'components/_base/InputFile';
import SingleSelect from 'components/_base/SelectSingle';

import semFoto from './sem-foto.jpg';
import { faTemperatureHigh } from '@fortawesome/free-solid-svg-icons';

const Upload = (props) => {
  const dispatch = useDispatch();
  const [materiais, setMateriais] = useState('');
  const [nextPageDisabled, setNextPageDisabled] = useState(true);

  const { changeFormData, onChangeTab, formData } = props;

  async function getMateriais(plano) {
    dispatch(LoaderAction.showLoader(faTemperatureHigh));
    const data = await GET_SELECT_LIST_MATERIAL_COMUNICACAO({ plano: plano });

    if (200 === data.code) {
      setMateriais(data.value || []);
    }

    dispatch(LoaderAction.showLoader(false));
  }

  function goToPrevPage() {
    onChangeTab('cliente');
  }
  async function goToNextPage() {
    onChangeTab('resumo-envio');
  }

  const uploadArquivo = async (event) => {
    event.preventDefault();
    dispatch(LoaderAction.showLoader(true));
    const formDataImage = new FormData();
    const file = event.target.files[0];
    const name = event.target.name;
    formDataImage.append('file', file);
    formDataImage.append('mesReferencia', formData.mesReferencia);
    if (file || formData.mesReferencia) {
      const data = await UPLOAD_ON_OFF_FILE(formDataImage);
      let novaLista = formData.onOffPlanoEnvioArquivos.map((value) => {
        return {
          urlArquivo: name && value.id == name ? data.value : value.urlArquivo,
          id: value.id,
        };
      });
      changeFormData({
        onOffPlanoEnvioArquivos: novaLista,
      });
    }

    dispatch(LoaderAction.showLoader(false));
  };

  function NavButtons() {
    return (
      <div>
        <Button className="onoff-button onoff-left" onClick={goToPrevPage}>
          <FaChevronLeft size="28" /> VOLTAR{' '}
        </Button>
        <Button
          className="onoff-button onoff-right"
          disabled={nextPageDisabled}
          onClick={goToNextPage}
        >
          AVANÇAR <FaChevronRight size="28" />
        </Button>
        {/* <ModalClientes /> */}
      </div>
    );
  }

  const RemoverImagemLista = async (arquivo) => {
    dispatch(LoaderAction.showLoader(true));
    const newOnOffPlanoEnvioArquivos = formData.onOffPlanoEnvioArquivos.filter(
      (item) => arquivo.id !== item.id
    );
    changeFormData({
      onOffPlanoEnvioArquivos: newOnOffPlanoEnvioArquivos,
    });

    const formDataRemoverImagem = new FormData();
    formDataRemoverImagem.append('url', arquivo.urlArquivo);

    if (arquivo.urlArquivo) {
      await DELETE_ONOFF_FILE(formDataRemoverImagem);
    }

    dispatch(LoaderAction.showLoader(false));
  };

  useEffect(() => {
    let valido = true;

    let possuiImagens = formData.onOffPlanoEnvioArquivos.filter(
      (item) => item.urlArquivo !== ''
    );

    if (
      !formData.mesReferencia ||
      !formData.tipoPlano ||
      !formData.subTitulo ||
      !formData.onOffMaterialComunicacaoId ||
      !formData.veiculacao === '' ||
      formData.onOffPlanoEnvioArquivos.length === 0 ||
      possuiImagens.length === 0
    ) {
      valido = false;
    }

    setNextPageDisabled(!valido);
  }, [formData]);

  return (
    <div>
      <h1 className="onoff-title">
        <FaFileUpload /> UPLOAD PLANO ON&OFF
      </h1>
      <div className="d-flex justify-content-center">
        <div className="onoff-content">
          <div className="mes-info">
            UPLOADS DE MÍDIAS DO MÊS: {formData.mesReferencia}
          </div>
          <Row>
            <Col lg={6}>
              <label>Selecione o Plano</label>
              <div>
                <SingleSelect
                  value={formData.tipoPlano}
                  name="plano"
                  onChange={(item) => {
                    getMateriais(item);
                    changeFormData({
                      tipoPlano: item,
                      onOffMaterialComunicacaoId: '',
                      material: '',
                    });
                  }}
                  options={[
                    { value: 'ON', label: 'ON' },
                    { value: 'OFF', label: 'OFF' },
                  ]}
                />
              </div>

              <label>Selecione o Tipo de Material</label>
              <div>
                <SingleSelect
                  value={formData.onOffMaterialComunicacaoId}
                  name="materialId"
                  onChange={(item) => {
                    let nomeItem = materiais[0].options.filter(
                      (materialAtual) => materialAtual.value == item
                    );

                    changeFormData({
                      onOffMaterialComunicacaoId: item,
                      material: nomeItem[0].label,
                    });
                  }}
                  options={materiais}
                  disabled={!materiais}
                />
              </div>
            </Col>

            <Col lg={6}>
              <label>Subtítulo</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  value={formData.subTitulo}
                  name="subtitulo"
                  placeholder="Digite o subtítulo..."
                  onChange={(e) =>
                    changeFormData({
                      subTitulo: e.target.value,
                    })
                  }
                />
              </div>

              <label>Período de Veiculação</label>
              <div>
                <SingleSelect
                  value={formData.veiculacao}
                  name="quinzena"
                  onChange={(item) =>
                    changeFormData({
                      veiculacao: item,
                    })
                  }
                  options={[
                    { value: 1, label: '1º Quinzena' },
                    { value: 2, label: '2º Quinzena' },
                  ]}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <label>Observações</label>
              <div>
                <textarea
                  type="text"
                  className="form-control"
                  value={formData.observacao}
                  rows={2}
                  name="observacao"
                  placeholder="Digite a sua mensagem..."
                  onChange={(event) => {
                    if (
                      formData.observacao.length < 50 &&
                      event.target.value.length <= 50
                    ) {
                      changeFormData({
                        observacao: event.target.value,
                      });
                    }
                  }}
                />
                <span className="text-size-info">
                  Caracteres: {formData.observacao.length}/50
                </span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <label>
                <b>Escolha os arquivos</b>{' '}
                <span
                  className="add-file cursor-pointer"
                  onClick={() => {
                    const id = '_' + Math.random().toString(36).substr(2, 20);
                    changeFormData({
                      onOffPlanoEnvioArquivos: [
                        ...formData.onOffPlanoEnvioArquivos,
                        {
                          urlArquivo: '',
                          id: id,
                        },
                      ],
                    });
                  }}
                >
                  <FaPlusCircle /> Incluir novo arquivo
                </span>
              </label>
              <div className="row mt-3">
                {formData.onOffPlanoEnvioArquivos &&
                  formData.onOffPlanoEnvioArquivos.map((arquivo, index) => (
                    <div
                      className="col-md-3"
                      style={{
                        textAlign: 'center',
                        padding: '15px',
                      }}
                      key={index}
                    >
                      <div className="imagem-on-off-envio">
                        <div className="image-container-on-off p-1 d-flex justify-content-end align-items-center">
                          {arquivo.urlArquivo ? (
                            <img
                              className="img-envio img-fluid w-100 d-block"
                              src={arquivo.urlArquivo}
                              alt=""
                            />
                          ) : (
                            <img
                              className="img-envio img-fluid w-100 d-block"
                              src={semFoto}
                              alt=""
                            />
                          )}
                        </div>
                        <InputFileCustom
                          name={arquivo.id}
                          onChange={(e) => uploadArquivo(e)}
                          placeholder="Escolha o arquivo"
                        />
                        <p
                          className="text-danger cursor-pointer"
                          onClick={() => RemoverImagemLista(arquivo)}
                        >
                          Remover Imagem
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </Col>
          </Row>
          <NavButtons />
        </div>
      </div>
    </div>
  );
};

export default Upload;
