/* eslint-disable */

import { MsalAuthProvider, LoginType } from 'react-aad-msal';

export const msalConfig = {
  auth: {
    clientId: 'ffb12148-6c5e-4838-97bd-e7b29f869946', // This is the ONLY mandatory field that you need to supply.
    authority: 'https://login.microsoftonline.com/27951710-ebb9-4efb-a4a5-48e460822d4b',
    clientSecret: '6qe8Q~_yuA5tc6AjvRK564Ggq8C7745_paRIdcBe',
    redirectUri: "https://maisqueimagensbrfas.com.br/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "https://maisqueimagensbrfas.com.br/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    validateAuthority: true,
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: "https://maisqueimagensbrfas.com.br/"
}
const authenticationParameters = {
  scopes: [
    'user.read'
  ]
}
export const authProvider = new MsalAuthProvider(msalConfig, authenticationParameters, options);
