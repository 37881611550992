import api from 'api';

const PATH = '/Regional';

const GET_REGIONAL_BY_ID = async (regionalId) => {
  const { data } = await api.get(`${PATH}/get/${regionalId}`);
  return data?.value;
};
const GET_LIST_FILIAL_REGIONAL = async (regionalId) => {
  const { data } = await api.get(
    `${PATH}/get-list-filial-por-regional/${regionalId}`
  );
  return data;
};
const GET_LIST_REGIONAL_SUPERIOR = async () => {
  const { data } = await api.get(`${PATH}/get-list-regional-superior`);
  return data;
};
const GET_SELECT_LIST_FILHAS = async () => {
  const { data } = await api.get(`${PATH}/get-select-list-filhas`);
  return data;
};
const GET_SELECT_LIST_REGIONAL = async (id) => {
  const { data } = await api.get(`${PATH}/get-select-list`, {
    params: id,
  });
  return data;
};

export {
  GET_REGIONAL_BY_ID,
  GET_LIST_FILIAL_REGIONAL,
  GET_LIST_REGIONAL_SUPERIOR,
  GET_SELECT_LIST_FILHAS,
  GET_SELECT_LIST_REGIONAL,
};
