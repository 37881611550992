/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

const ModalAvisoPendencia = (props) => {
  let pendenciasCount = props.pendenciasCount;
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    setShowModal(true);
  }, []);

  const handleClose = () => {
    props.onClose();
    setShowModal(false);
  };

  const show = () => {
    return pendenciasCount !== undefined && pendenciasCount !== null && (pendenciasCount > 0) && showModal;
  };

  return (
    <Modal show={show()} onHide={handleClose} className="modal-aviso-pendencia">
      <Modal.Header closeButton className="header">
        <Modal.Title>
          Atenção !!!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
        Você possui {pendenciasCount} pendências, verifique se precisa aprovar alguma solicitação ou enviar alguma evidência. Clique no botão "PENDÊNCIAS" e veja as solicitações.
        </p>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <Button
          variant="primary"
          onClick={handleClose}
          className="button-close"
        >
          FECHAR
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAvisoPendencia;
