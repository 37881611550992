import React from 'react';
import './styles.scss';
import { TEXTO_SEM_DADOS } from './const';

const SemDados = (props) => {
  const { titulo } = props;
  return (
    <div className="grafico">
      <div className="grafico-titulo">
        <span>{titulo || TEXTO_SEM_DADOS}</span>
      </div>
      <div className="grafico-sem-dados">
        <span>{TEXTO_SEM_DADOS}</span>
      </div>
    </div>
  );
};

export default SemDados;
