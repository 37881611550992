/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Modal, Button } from 'react-bootstrap';
import { compareString } from '../../../../_constants';

const ProdutosInovacao = (props) => {
  //let entities = props.entities;
  let solicitacaoProdutos = props.solicitacaoProdutos; // Produtos adicionados
  let adicionarProduto = props.adicionarProduto;

  const [produtos, setProdutos] = useState(props.produtos || []);

  const [codigoNome, setCodigoNome] = useState('');


  useEffect(() => {
    searchProdutos();
  }, [codigoNome]);

  const searchProdutos = () => {
    let produtos = props.produtos || [];

    if (solicitacaoProdutos.length > 0)
      produtos = produtos.filter(
        (produto) =>
          solicitacaoProdutos.findIndex(
            (sp) => sp.produtoId === produto.produtoId
          ) === -1
      );

    if (codigoNome.length > 0)
      produtos = produtos.filter(
        (produto) =>
          compareString(produto.nome, codigoNome) ||
          compareString(produto.codigo, codigoNome)
      );

    setProdutos([...produtos]);
  };

  const renderFiltro = () => {
    return (
      <div className="row mt-3 mb-3">
        <div className="col-lg-6">

          <div className="mb-4">
            <label>Código ou Nome do produto</label>
            <input
              type="text"
              className="form-control"
              value={codigoNome || ''}
              onChange={(event) => setCodigoNome(event.target.value)}
            />
          </div>
        </div>
      </div>
    );

    return null;
  };

  const renderProdutos = () => {
    if (produtos.length === 0) {
      return <p>Nenhum produto encontrado.</p>;
    }

    return (
      <div className="table-search-produtos-wrapper">
        <table className="table table-striped table-search-produtos">
          <thead>
            <tr>
              <th>Codigo</th>
              <th>Nome</th>
              <th>Marca</th>
              <th>Categoria</th>
              {/* <th>Inovacao</th> */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {produtos.map((produto, index) => {
              if (produto.inovacao) {
                return (
                  <tr key={index}>
                    <td>{produto.codigo}</td>
                    <td>{produto.nome}</td>
                    <td>{produto.marca}</td>
                    <td>{produto.categoria}</td>
                    {/* <td>{produto.inovacao? "Sim": "Não"}</td> */}
                    <td>
                      <button
                        className="btn btn-primary btn-navigation btn-navigation-xs btn-add-inovacao"
                        onClick={() => adicionarProduto(produto)}
                      >
                        <span>+ Adicionar</span>
                      </button>
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      {renderFiltro()}
      {renderProdutos()}
    </div>
  );
};

export default ProdutosInovacao;
