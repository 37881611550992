import '../styles.scss';
import React from 'react';
import SemDados from '../SemDados';

const VerbaDisponivel = (props) => {
  const { data } = props;
  const titulo = 'VERBA DISPONÍVEL POR';

  if (!data || data.length < 1) {
    return <SemDados titulo={titulo} />;
  }

  const dataAtual = new Date();
  const ultimoDia = new Date(
    dataAtual.getFullYear(),
    dataAtual.getMonth() + 1,
    0
  );
  const diasRestantes = ultimoDia.getDate() - dataAtual.getDate();

  const itensNome = data.map((item) => {
    return item.nome;
  });

  const itensCusto = data.map((item) => {
    return item.totalCusto;
  });

  const itensVerba = data.map((item) => {
    return item.totalVerba;
  });

  const itensVerbaDisponivel = itensCusto.map((item, index) => {
    return itensVerba[index] - item;
  });

  const formatarValor = (valor) => {
    return `R$${valor.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
    })}`;
  };

  return (
    <div className="grafico">
      <div className="grafico-titulo">
        <span>
          {titulo} {diasRestantes} DIAS, VÁLIDO ATÉ{' '}
          {ultimoDia.toLocaleDateString('pt-BR', { timeZone: 'UTC' })}
        </span>
      </div>
      <div className="tabela-container">
        <table className="tabela">
          <thead>
            <tr>
              <th style={{ width: '15%' }} aria-label="header" key="he" />
              {itensNome.map((item, index) => {
                return (
                  <th
                    style={{ width: `${85 / itensNome.length}%` }}
                    key={`h${itensNome[index]}`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td key="tc">Custo Total</td>
              {itensCusto.map((item, index) => {
                return (
                  <td key={`tc${itensNome[index]}`}>{formatarValor(item)}</td>
                );
              })}
            </tr>
            <tr>
              <td key="tvr">Verba por Regional</td>
              {itensVerba.map((item, index) => {
                return (
                  <td key={`tvr${itensNome[index]}`}>{formatarValor(item)}</td>
                );
              })}
            </tr>
            <tr>
              <td key="tvd">Verba Disponível</td>
              {itensVerbaDisponivel.map((item, index) => {
                return (
                  <td key={`tvd${itensNome[index]}`}>{formatarValor(item)}</td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VerbaDisponivel;
