/* eslint-disable no-plusplus */
import React, { useEffect, useState, useContext } from 'react';
import { Button } from 'react-bootstrap';

import { Context } from 'context/AuthContext';
import { GET_CHAMADO_USER_ID } from 'services/Chamado';

import './styles.scss';

const OpenCalled = () => {
  const userAuth = JSON.parse(localStorage.getItem('midias_brf_as'));
  const [chamados, setChamados] = useState([]);
  const [contadorChamado, setContadorChamado] = useState(0);
  const { showLoader } = useContext(Context);

  useEffect(() => {
    (async () => {
      showLoader(true);

      // Todos os chamados
      const data = await GET_CHAMADO_USER_ID(userAuth.userId);
      setChamados(data.value);

      // Numero de chamados que não foi finalizado
      const chamadosAbertos = data.value.filter(
        (item) => item.dataFinalizacao === null
      );
      setContadorChamado(chamadosAbertos.length);

      showLoader(false);
    })();
  }, []);

  return (
    <div>
      <div className="title">Abrir Chamado</div>
      <div className="info">
        <div className="row">
          <div className="col-md-12 botoes">
            <Button
              href="/chamado"
              className=" col-lg-5 col-sm-5 query-button-called"
              variant="primary"
            >
              <small>ABRIR NOVO CHAMADO</small>
            </Button>
            <Button
              style={{
                backgroundColor: chamados.length > 0 ? 'red' : null,
                borderColor: chamados.length > 0 ? 'red' : null,
              }}
              id="acompanhar"
              href="/acompanhar-chamado"
              className="col-lg-5 col-sm-5  query-button-called "
              variant="primary"
            >
              <small>CHAMADOS ABERTOS ({contadorChamado}) </small>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenCalled;
