import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
import SemDados from '../SemDados';

const ProdutoRank = (props) => {
  const { data } = props;
  const titulo = 'RANK PRODUTOS SOLICITADOS';

  if (!data || data.length < 1) {
    return <SemDados titulo={titulo} />;
  }

  const itens = data.map((item) => {
    return item.nome;
  });

  const itensValor = data.map((item) => {
    return item.totalSolicitacao;
  });

  const series = [
    {
      data: itensValor,
    },
  ];

  const options = {
    colors: ['#2d2f93'],
    plotOptions: {
      bar: {
        barHeight: '80%',
        horizontal: true,
        dataLabels: {
          position: 'top',
          orientation: 'horizontal',
        },
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      categories: itens,
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      max: Math.max(...itensValor) * 1.1,
      labels: {
        align: 'right',
        maxWidth: 200, // largura maxima da label
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
        // formatter: (value, index) => {
        //   return `${value} (${itensPorcentagem[index]}%)`;
        // },
      },
    },
    grid: {
      padding: {
        left: 20,
      },
      row: {
        colors: ['#dcdcdc', 'transparent'],
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      parentHeightOffset: 0,
      width: '100%',
    },
    dataLabels: {
      hideOverflowingLabels: false,
      textAnchor: 'start',
      offsetX: 5,
      offsetY: 6,
      // formatter: (value, { dataPointIndex }) => {
      //   return `R$${parseInt(value, 10).toLocaleString('pt-BR')} (${
      //     itensPorcentagem[dataPointIndex]
      //   }%)`;
      // },
      style: {
        fontSize: '0.75rem',
        fontWeight: 'bold',
        colors: ['#373d3f'],
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      intersect: false,
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return `<div class="arrow_box">
            <span>
              Produto: <span id="data">${itens[dataPointIndex]}</span>
            </span>
            <span>
            Solicitações Aprovadas: <span id="data">${parseInt(
              series[seriesIndex][dataPointIndex],
              10
            ).toLocaleString('pt-BR')}</span>
          </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico" style={{ overflow: 'hidden' }}>
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <div className="scrollable">
        <Chart
          options={options}
          series={series}
          height={itens.length * 17 + 100}
          type="bar"
        />
      </div>
    </div>
  );
};

export default ProdutoRank;
