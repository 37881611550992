import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
import SemDados from '../SemDados';

const PrecoMedioEncarte = (props) => {
  const { data } = props;
  // LEMBRAR DE VERIFICAR ENCARTE EXCLUSIVO SIMPLES NO BACKEND
  // LEMBRAR DE VERIFICAR ENCARTE EXCLUSIVO SIMPLES NO BACKEND
  // LEMBRAR DE VERIFICAR ENCARTE EXCLUSIVO SIMPLES NO BACKEND
  // LEMBRAR DE VERIFICAR ENCARTE EXCLUSIVO SIMPLES NO BACKEND
  // LEMBRAR DE VERIFICAR ENCARTE EXCLUSIVO SIMPLES NO BACKEND
  // LEMBRAR DE VERIFICAR ENCARTE EXCLUSIVO SIMPLES NO BACKEND
  // LEMBRAR DE VERIFICAR ENCARTE EXCLUSIVO SIMPLES NO BACKEND
  const titulo = 'PREÇO MÉDIO - ENCARTE EXCLUSIVO SIMPLES';

  if (!data || data.length < 1) {
    return <SemDados titulo={titulo} />;
  }

  const itensObrigatorio = data.map((item) => {
    return item.precoMedioObrigatorio;
  });

  const itensNaoObrigatorio = data.map((item) => {
    return item.precoMedioNaoObrigatorio;
  });

  const somaArrays = itensObrigatorio.map((item, index) => {
    return (item + itensNaoObrigatorio[index]) / 2;
  });

  const valorMedio = somaArrays.reduce((a, b) => a + b, 0) / somaArrays.length;

  const months = data.map((item) => {
    return item.nome;
  });

  const series = [
    {
      name: 'Obrigatório',
      data: itensObrigatorio,
    },
    {
      name: 'Não Obrigatório',
      data: itensNaoObrigatorio,
    },
  ];

  const formatarValor = (valor) => {
    return `R$${valor.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
    })}`;
  };

  const options = {
    annotations: {
      yaxis: [
        {
          y: valorMedio,
          strokeDashArray: 7,
        },
      ],
    },
    colors: ['#F39221', '#a4a4a4'],
    legend: {
      showForSingleSeries: true,
      position: 'bottom',
      horizontalAlign: 'left',
    },
    xaxis: {
      categories: [...months],
      tickPlacement: 'between',
    },
    yaxis: {
      show: true,
      showForNullSeries: true,
      labels: {
        formatter: (value) => {
          return formatarValor(value);
        },
      },
      axisBorder: {
        show: true,
      },
    },
    grid: {
      show: false,
    },
    chart: {
      id: 'evo-solicitacoes',
      parentHeightOffset: 0,
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      width: [3, 3],
      show: true,
      curve: 'straight',
      dashArray: 0,
    },
    markers: {
      size: 8,
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'middle',
      style: {
        fontSize: '0.8rem',
        colors: ['#F39221', '#a4a4a4'],
      },
      background: {
        enabled: true,
        foreColor: '#fff',
        opacity: 0.8,
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      intersect: true,
      fillSeriesColor: true,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        return `<div class="arrow_box">
            <span>
              Mês: <span id="data">${months[dataPointIndex]}</span>
            </span>
            <span>
              Preço Médio Obrigatório: <span id="data">${formatarValor(
                itensObrigatorio[dataPointIndex]
              )}</span>
            </span>
            <span>
              Preço Médio Não Obrigatório: <span id="data">${formatarValor(
                itensNaoObrigatorio[dataPointIndex]
              )}</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico">
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <Chart options={options} series={series} type="line" height="250" />
    </div>
  );
};

export default PrecoMedioEncarte;
