import '../styles.scss';
import React from 'react';
import SemDados from '../SemDados';

const ProdutoEncarte = (props) => {
  const { data } = props;
  const titulo = 'ENCARTES POR PRODUTO';

  if (!data || data.length < 1) {
    return <SemDados titulo={titulo} />;
  }

  const itensQtde = data.map((item) => {
    return item.total;
  });

  const itensQtdeObrigatorio = data.map((item) => {
    return item.totalObrigatorio;
  });

  const itensQtdeNaoObrigatorio = data.map((item) => {
    return item.totalNaoObrigatorio;
  });

  // const maxObrigatorio = Math.max(...itensQtdeObrigatorio);
  // const maxNaoObrigatorio = Math.max(...itensQtdeNaoObrigatorio);

  const colors = ['#F39221', '#D3D3D3'];

  // retorna uma barra com tamanho relativo a quantidade de solicitações
  const getBar = (qtde, total, index) => {
    const largura = Math.round((qtde * 100) / total);
    return (
      <div className="celula-barra">
        <div
          className="barra"
          style={{
            width: `${largura * 0.7}%`,
            backgroundColor: colors[index % colors.length],
          }}
        />
        <div className="barra-valor">{qtde}</div>
      </div>
    );
  };

  return (
    <div className="grafico">
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <div className="tabela-container" style={{ overflowX: 'hidden' }}>
        <table className="tabela">
          <thead>
            <tr key="rh">
              <th className="fixed-header" style={{ width: '30%' }} key="h">
                Categoria
              </th>
              <th className="fixed-header" style={{ width: '14%' }} key="ht">
                Total
              </th>
              <th className="fixed-header" style={{ width: '28%' }} key="ho">
                Obrigatório
              </th>
              <th className="fixed-header" style={{ width: '28%' }} key="hno">
                Não Obrigatório
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <React.Fragment key={`rf${item.nome}`}>
                  <tr
                    key={`r${item.nome}`}
                    style={{ backgroundColor: '#fafafa' }}
                  >
                    <td key={`r${item.nome}`}>
                      {item.nome !== 'nulo' ? item.nome : 'SEM PRODUTO'}
                    </td>
                    <td key={`rt${item.nome}`}>{itensQtde[index]}</td>
                    <td key={`ro${item.nome}`}>
                      {getBar(itensQtdeObrigatorio[index], itensQtde[index], 0)}
                    </td>
                    <td key={`rno${item.nome}`}>
                      {getBar(
                        itensQtdeNaoObrigatorio[index],
                        itensQtde[index],
                        1
                      )}
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProdutoEncarte;
