/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { FaAngleRight } from 'react-icons/fa';

import { passos } from '../../../_constants';

const Layout = (props) => {
  let entities = props.entities;
  let solicitacao = props.solicitacao;
  let avancar = props.avancar;
  let renderErrors = props.renderErrors;
  let errors = (solicitacao.errors || []).filter(
    (error) => error.passo === passos.passoLayout.nro
  );

  solicitacao.solicitacaoDados = solicitacao.solicitacaoDados || {};

  let layouts = (entities.clienteLayouts.layoutArtes || []).filter(
    (layout) =>
      layout.campanhaId === null &&  // Elimina os layouts de Campanha
      layout.layoutArteMateriaisComunicacao.findIndex(
        (lm) => lm.materialComunicacaoId === solicitacao.materialComunicacaoId
      ) !== -1
  );

  const [layoutArteId, setLayoutArteId] = useState(
    solicitacao.solicitacaoDados.layoutArteId || 0
  );

  useEffect(() => {
    setLayoutArteId(solicitacao.solicitacaoDados.layoutArteId || 0);
  }, [solicitacao]);

  const renderLayouts = () => {
    if (!layouts.length) return null;

    return (
      <div className="row">
        {layouts
          .sort(function (a, b) {
            return a.descricao.localeCompare(b.descricao);
          })
          .map((layout, index) => {
            return (
              <div key={index} className="col-lg-4 mg-3">
                <label>
                  <input
                    type="radio"
                    checked={layout.layoutArteId === layoutArteId}
                    onChange={() => {
                      solicitacao.solicitacaoDados.layoutArteId =
                        layout.layoutArteId;
                      setLayoutArteId(
                        solicitacao.solicitacaoDados.layoutArteId
                      );
                    }}
                  />{' '}
                  {layout.descricao}
                  <img src={layout.urlImagem} className="img-fluid" />
                </label>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div>
      {renderErrors(errors)}

      {renderLayouts()}

      <div className="row mt-5">
        <div className="col-lg-12 d-flex flex-row-reverse">
          <button
            className="btn btn-primary btn-navigation btn-navigation-right"
            onClick={() => avancar()}
          >
            <span>AVANÇAR</span>
            <span>
              <FaAngleRight size={26} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Layout;
