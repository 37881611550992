/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import { FaSave, FaArrowLeft } from 'react-icons/fa';

import {
  GET_USUARIOTIPOS_SELECTLIST,
  GET_USUARIOSUPERIOR_SELECTLIST,
  GET_REGIONAIS_SELECTLIST,
  CADASTRAR
} from 'services/UsuarioAutoCadastro';

import { Context } from 'context/AuthContext';
import { useHistory } from 'react-router-dom';

import Select from 'react-select';

const ContentUsuarioAutoCadastro = () => {
  const { showLoader } = useContext(Context);
  const [regionalList, setRegionalList] = useState([]);
  const [usuarioTiposList, setUsuarioTiposList] = useState([]);
  const [usuarioSuperiorList, setUsuarioSuperiorList] = useState([]);
  const [formData, setFormData] = useState({
    usuarioId: '',
    nome: '',
    cpf: '',
    telefone: '',
    email: '',
    regional: '',
    filial: '',
    usuarioTipo: '',
    superior: '',
    senha: '',
    senhaConfirmacao: '',
    erros: [],
  });

  const history = useHistory();

  //-------------------------------------------------------------------------------------------
  // Effects
  useEffect(() => {
    (async () => {
      showLoader(true);

      setRegionalList(await GET_REGIONAIS_SELECTLIST());
      setUsuarioTiposList(await GET_USUARIOTIPOS_SELECTLIST());
      setUsuarioSuperiorList(await GET_USUARIOSUPERIOR_SELECTLIST());

      showLoader(false);
    })();
  }, []);

  //-------------------------------------------------------------------------------------------
  // Subfuncoes
  const onSubmit = async () => {
    showLoader(true);

    const postData = {
      usuarioId: formData.usuarioId,
      userName: formData.email,       // Usa email como nome de login
      nome: formData.nome,
      cpf: formData.cpf,
      email: formData.email,
      telefone: formData.telefone,
      regionalId: formData.regional.value,
      filialId: null,
      usarioSuperiorId: formData.superior.value,
      usuarioTipoId: formData.usuarioTipo.value,
      senha: formData.senha,
      senhaConfirmacao: formData.senhaConfirmacao,
    }

    let result = await CADASTRAR(postData);

    if (result && (result.errors == null || result.errors.length == 0)) {
      // Sucesso - redireciona para pagina de login
      history.push("/usuario-autocadastro/sucesso");
    }
    else {
      // Falha - exibe os erros da tentativa de cadastro
      if (result) {
        setFormData({ ...formData, erros: result.errors });
      }
      else {
        setFormData({ ...formData, erros: ["Falha - Erro desconhecido, por favor verifique os dados e tente novamente."] });
      }
    }

    showLoader(false);
  }

  //-------------------------------------------------------------------------------------------
  // Render
  return (
    <div className="container-fluid mb-5">
      <div className="container">
        <div className="row">

          {/* Errors -------------------------------------------------------------------------- */}
          <div className="col-md-10 offset-md-1 mb-2 mt-2">
            {formData.erros && formData.erros.length !== 0 && (
              <div className="alert alert-danger">
                {formData.erros.map((value, index) => {
                  return (
                    <p className="m-0" key={index}>
                      {value}
                    </p>
                  );
                })}
              </div>
            )}
          </div>

          {/* Nome ---------------------------------------------------------------------------- */}
          <div className="col-md-10 offset-md-1 mb-3">
            <label>Nome Completo</label>
            <input
              type="text"
              className="form-control"
              value={formData.nome}
              onChange={(e) =>
                setFormData({ ...formData, nome: e.target.value })
              }
            />
          </div>

          {/* CPF ----------------------------------------------------------------------------- */}
          <div className="col-md-5 offset-md-1 mb-3">
            <label>CPF</label>
            <input
              type="text"
              className="form-control"
              value={formData.cpf}
              onChange={(e) => {
                let cpfNoMask = e.target.value.replace(/\D+/g, '');
                if (cpfNoMask.length <= 11) {
                  setFormData({
                    ...formData,
                    cpf: e.target.value.replace(/\D+/g, ''),
                  });
                }
              }}
            />
          </div>

          {/* Telefone ------------------------------------------------------------------------ */}
          <div className="col-md-5 ">
            <label>Telefone de contato</label>
            <input
              type="text"
              className="form-control"
              value={formData.telefone}
              onChange={(e) => {
                let phoneNoMask = e.target.value.replace(/\D+/g, '');
                if (phoneNoMask.length <= 11) {
                  setFormData({
                    ...formData,
                    telefone: e.target.value.replace(/\D+/g, ''),
                  });
                }
              }}
            />
          </div>

          {/* Email --------------------------------------------------------------------------- */}
          <div className="col-md-10 offset-md-1 mb-3">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </div>

          {/* Regional ------------------------------------------------------------------------ */}
          <div className="col-md-10 offset-md-1 mb-3">
            <label>Regional</label>
            <Select
              name="regionais"
              options={regionalList}
              classNamePrefix="select"
              placeholder="Selecione..."
              onChange={(item) => {
                //atualizarListaFilial(item.value);
                setFormData({
                  ...formData,
                  regional: item,
                  filial: '',
                });
              }}
              value={formData.regional}
            />
          </div>

          {/* Tipo Usuario -------------------------------------------------------------------- */}
          <div className="col-md-5 offset-md-1 mb-3">
            <label>Função na empresa</label>
            <Select
              name="usuariotipos"
              options={usuarioTiposList}
              classNamePrefix="select"
              placeholder="Selecione..."
              onChange={(item) => {
                //item.dados.usuarioTipoSuperiorId &&
                //atualizarListaUsuarioSuperior(item.dados.usuarioTipoId);
                setFormData({
                  ...formData,
                  usuarioTipo: item,
                  superior: '',
                });
              }}
              value={formData.usuarioTipo}
            />
          </div>

          {/* Usuario Superior ---------------------------------------------------------------- */}
          <div className="col-md-5 mb-3">
            <label>Supervisor</label>
            <Select
              name="usuariossuperiores"
              options={usuarioSuperiorList}
              classNamePrefix="select"
              placeholder="Selecione..."
              isDisabled
              // isDisabled={
              //   !formData.funcao ||
              //   formData.funcao.dados.usuarioTipoSuperiorId == null
              // }
              onChange={(item) => {
                setFormData({
                  ...formData,
                  superior: item,
                });
              }}
              value={formData.superior}
            />
          </div>

          {/* Senha --------------------------------------------------------------------------- */}
          <div className="col-md-5 offset-md-1 mb-3">
            <label>Senha</label> <br />
            <input
              type="password"
              className="form-control"
              value={formData.senha}
              onChange={(e) =>
                setFormData({ ...formData, senha: e.target.value })
              }
            />
            <label className="text-secondary small">
              Mínimo 6 dígitos, contendo letras e números.
            </label>
          </div>

          {/* Confirmacao Senha --------------------------------------------------------------- */}
          <div className="col-md-5 mb-3">
            <label>Confirmar senha</label>
            <input
              type="password"
              className="form-control"
              value={formData.senhaConfirmacao}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  senhaConfirmacao: e.target.value,
                })
              }
            />
            <label className="text-secondary small">
              Mínimo 6 dígitos, contendo letras e números.
            </label>
          </div>

          {/* Botao Voltar -------------------------------------------------------------------- */}
          <div className="col-5 mt-2 col btn-avancar-voltar d-flex flex-row-reverse">
            <a href='/'>
              <button
                className={`btn btn-primary btn-lg btn-navigation btn-navigation-right pl-5 pr-5`}
              >
                <span className="mr-2">
                  <FaArrowLeft size={26} />
                </span>
                <span>VOLTAR</span>
              </button>
            </a>
          </div>

          {/* Botao Salvar -------------------------------------------------------------------- */}
          <div className="col-5 offset-1 mt-2 col btn-avancar-voltar d-flex flex-row-reverse">
            <button
              className={`btn btn-primary btn-lg btn-navigation btn-navigation-right pl-5 pr-5`}
              onClick={() => { onSubmit() }}
            >
              <span className="mr-2">
                <FaSave size={26} />
              </span>
              <span>SALVAR</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentUsuarioAutoCadastro;
