/* eslint-disable prefer-destructuring */
/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import { FaSave } from 'react-icons/fa';
import VMasker from 'vanilla-masker';

import api from 'api';
import {
  GET_LIST_FILIAL_REGIONAL,
  GET_LIST_REGIONAL_SUPERIOR,
  GET_SELECT_LIST_FILHAS,
} from 'services/Regional';
import {
  GET_USUARIO,
  GET_LIST_USUARIO_TIPO,
  GET_LIST_USUARIO_SUPERIOR,
  UPDATE_DADOS_CADASTRO_USUARIO,
} from 'services/Usuario';
import { Context } from 'context/AuthContext';

import TitlePages from 'components/_base/TitlePages';
import Select from 'react-select';

const ContentValidacaoDadosUsuario = () => {
  const { showLoader, userData, setUserData } = useContext(Context);
  const [formData, setFormData] = useState({
    userId: '',
    nome: '',
    telefone: '',
    email: '',
    regional: '',
    filial: '',
    funcao: '',
    superior: '',
    senhaAtual: '',
    novaSenha: '',
    novaSenhaConfirmacao: '',
    erros: [],
  });
  const [regionalList, setRegionalList] = useState('');
  const [filialList, setFilialList] = useState('');
  const [funcaoList, setFuncaoList] = useState('');
  const [usuariosSuperioresList, setUsuariosSuperioresList] = useState('');

  useEffect(() => {
    (async () => {
      showLoader(true);

      // Pegar lista de funções
      let data = await GET_LIST_USUARIO_TIPO();
      let tiposUsuario = data.value.map((usuario) => {
        return {
          label: usuario.nome,
          value: usuario.usuarioTipoId,
          dados: usuario,
        };
      });
      setFuncaoList(tiposUsuario);

      // Pegar lista de regional

      let regionalListaLoad = await GET_LIST_REGIONAL_SUPERIOR();
      regionalListaLoad = regionalListaLoad.value.map((regional) => {
        return {
          label: regional.nome,
          value: regional.regionalId,
          dados: regional,
        };
      });
      setRegionalList(regionalListaLoad);

      // Pegar dados do usuario logado
      const userAuth = JSON.parse(localStorage.getItem('midias_brf_as'));
      const {value: dadosUserOn } = await GET_USUARIO(userAuth.userId);
      let userFuncao = tiposUsuario.filter(
        (item) => item.value === dadosUserOn.usuarioTipoId
      );
      userFuncao = userFuncao[0];

      let superiorObj = '';
      if (userFuncao) {
        let list = await atualizarListaUsuarioSuperior(
          userFuncao.dados.usuarioTipoId
        );
        superiorObj = list.filter(
          (item) => item.value === dadosUserOn.usuarioSuperiorId
        );
        superiorObj = superiorObj[0];
      }

      let regionalListaFilhas = await GET_SELECT_LIST_FILHAS();

      let regional = '';
      regionalListaFilhas.value.forEach((elem) => {
        elem.options.forEach((filial) => {
          if (parseInt(filial.value) === dadosUserOn.regionalId) {
            regional = elem.label;
          }
        });
      });
      let filial = '';
      if (regional) {
        regional = regionalListaLoad.filter((item) => item.label === regional);
        regional = regional[0];
        filial = await atualizarListaFilial(
          regional.value,
          dadosUserOn.regionalId
        );
        filial = filial[0];
      }
      setFormData({
        ...formData,
        userId: dadosUserOn.usuarioId,
        nome: dadosUserOn.nome,
        telefone: dadosUserOn.telefone,
        email: dadosUserOn.email,
        funcao: userFuncao,
        regional: regional,
        filial: filial,
        superior: superiorObj,
      });
      showLoader(false);
    })();
  }, []);

  const atualizarListaFilial = async (regionalId, filial) => {
    showLoader(true);

    const data = await GET_LIST_FILIAL_REGIONAL(regionalId);
    let value = data.value.map((filial) => {
      return {
        label: filial.nome,
        value: filial.regionalId,
        dados: filial,
      };
    });
    setFilialList(value);

    showLoader(false);
    if (filial) {
      let filialAtiva = value.filter((item) => item.value === filial);
      return filialAtiva;
    }
    return value;
  };

  const atualizarListaUsuarioSuperior = async (usuarioInferiorId) => {
    showLoader(true);

    let data = await GET_LIST_USUARIO_SUPERIOR({
      tipoUsuarioInferior: usuarioInferiorId,
    });

    if (data.value) {
      let newData = data.value.map((usuarioSuperior) => {
        return {
          label: usuarioSuperior.nome,
          value: usuarioSuperior.usuarioId,
          dados: usuarioSuperior,
        };
      });

      setUsuariosSuperioresList(newData);
    }
    showLoader(false);
    return data.value;
  };

  const telefoneValue = (numeroNormalizado) => {
    let input = numeroNormalizado || "";
    input = VMasker.toPattern(input, '99999999999999999999');
    if (input.length > 10) {
      input = VMasker.toPattern(input, '(99) 99999-9999');
    } else {
      input = VMasker.toPattern(input, '(99) 9999-9999');
    }
    return input;
  };

  const validacaoForm = () => {
    let erros = [];

    !formData.nome && erros.push('O campo Nome é obrigatório.');
    let validarEmail = /\S+@\S+\.\S+/;
    if (!formData.email || !validarEmail.test(formData.email)) {
      erros.push(`O campo Email é obrigatório.`);
    }
    if (!formData.telefone || formData.telefone.length < 10) {
      erros.push(`O campo Telefone é obrigatório.`);
    }
    !formData.regional && erros.push('O campo Regional é obrigatório.');
    !formData.filial && erros.push('O campo Filial é obrigatório.');
    !formData.funcao && erros.push('O campo Função é obrigatório.');
    if (
      !formData.superior &&
      formData.funcao.dados?.usuarioTipoSuperiorId !== null
    ) {
      erros.push('O campo Superior é obrigatório.');
    }
    !formData.senhaAtual && erros.push('O campo Senha atual é obrigatório.');
    !formData.novaSenha && erros.push('O campo Nova senha é obrigatório.');
    !formData.novaSenhaConfirmacao &&
      erros.push('O campo Confirme nova senha é obrigatório.');
    formData.novaSenhaConfirmacao !== formData.novaSenha &&
      erros.push(
        'O campo Nova senha e confirmar nova senha precisa ser igual.'
      );
    if (
      formData.novaSenhaConfirmacao === formData.novaSenha &&
      formData.novaSenhaConfirmacao &&
      formData.novaSenha
    ) {
      let regexValidarSenha = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
      !regexValidarSenha.test(formData.novaSenha) &&
        erros.push(
          'A senha deve conter pelo menos 6 caracteres contendo letras e numeros.'
        );
    }
    setFormData({
      ...formData,
      erros: erros,
    });

    if (erros.length !== 0) {
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    let validacao = validacaoForm();
    if (validacao) {
      showLoader(true);
      let requestBody = {
        usuario: {
          usuarioId: formData.userId,
          nome: formData.nome,
          usuarioSuperiorId: formData.superior.value,
          regionalId: formData.filial.value,
          email: formData.email,
          telefone: formData.telefone,
          usuarioTipoId: formData.funcao.value,
        },
        senhaAtual: formData.senhaAtual,
        novaSenha: formData.novaSenha,
        confirmacaoNovaSenha: formData.novaSenhaConfirmacao,
      };
      const atualizacaoEnvio = await UPDATE_DADOS_CADASTRO_USUARIO(requestBody);
      if (atualizacaoEnvio.value) {
        let userAuth = JSON.parse(localStorage.getItem('midias_brf_as'));
        userAuth = {
          ...userAuth,
          atualizarDados: false,
        };
        setUserData(userAuth);
        localStorage.setItem('midias_brf_as', JSON.stringify(userAuth));
      } else {
        setFormData({ ...formData, erros: atualizacaoEnvio.errors });
      }
      showLoader(false);
    }
  };

  return (
    <div className="container-fluid mb-5">
      <div className="row">
        <TitlePages title={`CONFIRME OS SEUS DADOS`} />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1 mb-2 mt-2">
            {formData.erros && formData.erros.length !== 0 && (
              <div className="alert alert-danger">
                {formData.erros.map((value, index) => {
                  return (
                    <p className="m-0" key={index}>
                      {value}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
          <div className="col-md-10 offset-md-1 mb-3">
            <label>Nome Completo</label>
            <input
              type="text"
              className="form-control"
              value={formData.nome}
              onChange={(e) =>
                setFormData({ ...formData, nome: e.target.value })
              }
            />
          </div>
          <div className="col-md-5 offset-md-1 mb-3">
            <label>Telefone de contato</label>
            <input
              type="text"
              className="form-control"
              value={telefoneValue(formData.telefone)}
              onChange={(e) => {
                let phoneNoMask = e.target.value.replace(/\D+/g, '');
                if (phoneNoMask.length <= 11) {
                  setFormData({
                    ...formData,
                    telefone: e.target.value.replace(/\D+/g, ''),
                  });
                }
              }}
            />
          </div>
          <div className="col-md-5 mb-3">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </div>
          <div className="col-md-5 offset-md-1 mb-3">
            <label>Regional</label>
            <Select
              name="colors"
              options={regionalList}
              classNamePrefix="select"
              placeholder="Selecione..."
              onChange={(item) => {
                atualizarListaFilial(item.value);
                setFormData({
                  ...formData,
                  regional: item,
                  filial: '',
                });
              }}
              value={formData.regional}
            />
          </div>
          <div className="col-md-5 mb-3">
            <label>Filial</label>
            <Select
              name="colors"
              options={filialList}
              isDisabled={!formData.regional}
              classNamePrefix="select"
              placeholder="Selecione..."
              onChange={(item) => {
                setFormData({
                  ...formData,
                  filial: item,
                });
              }}
              value={formData.filial}
            />
          </div>
          <div className="col-md-5 offset-md-1 mb-3">
            <label>Função na empresa</label>
            <Select
              name="colors"
              options={funcaoList}
              classNamePrefix="select"
              placeholder="Selecione..."
              onChange={(item) => {
                item.dados.usuarioTipoSuperiorId &&
                  atualizarListaUsuarioSuperior(item.dados.usuarioTipoId);
                setFormData({
                  ...formData,
                  funcao: item,
                  superior: '',
                });
              }}
              value={formData.funcao}
            />
          </div>
          <div className="col-md-5 mb-3">
            <label>Usuário Superior</label>
            <Select
              name="colors"
              options={usuariosSuperioresList}
              classNamePrefix="select"
              placeholder="Selecione..."
              isDisabled={
                !formData.funcao ||
                formData.funcao.dados.usuarioTipoSuperiorId == null
              }
              onChange={(item) => {
                setFormData({
                  ...formData,
                  superior: item,
                });
              }}
              value={formData.superior}
            />
          </div>
          <div className="col-10 offset-1 mb-3">
            <label>Senha atual</label>
            <input
              type="password"
              className="form-control"
              value={formData.novasenhaconfirmacao}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  senhaAtual: e.target.value,
                })
              }
            />
          </div>
          <div className="col-md-5 offset-md-1 mb-3">
            <label>Nova senha</label> <br />
            <input
              type="password"
              className="form-control"
              value={formData.novaSenha}
              onChange={(e) =>
                setFormData({ ...formData, novaSenha: e.target.value })
              }
            />
            <label className="text-secondary">
              Minimo 6 digitos, contendo letras e numeros.
            </label>
          </div>
          <div className="col-md-5 mb-3">
            <label>Confirmar nova senha</label>
            <input
              type="password"
              className="form-control"
              value={formData.novaSenhaConfirmacao}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  novaSenhaConfirmacao: e.target.value,
                })
              }
            />
            <label className="text-secondary">
              Minimo 6 digitos, contendo letras e numeros.
            </label>
          </div>
          <div className="col-10 offset-1 mt-2 col btn-avancar-voltar d-flex flex-row-reverse">
            <button
              className={`btn btn-primary btn-lg btn-navigation btn-navigation-right pl-5 pr-5 ${
                true ? '' : 'btn-disabled'
              }`}
              onClick={() => onSubmit()}
            >
              <span className="mr-2">
                <FaSave size={26} />
              </span>
              <span>SALVAR</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentValidacaoDadosUsuario;
