/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import history from 'routes/history';

import './styles.scss';
import api from 'api';
import { Context } from 'context/AuthContext';
import TitlePages from 'components/_base/TitlePages';
import Summary from 'components/Summary';
import ModalSummary from 'components/ModalSummary';
import ModalComment from './ModalComment';
import SolicitacaoArquivos from './SolicitacaoArquivos';
import Historico from './Historico';
import Fornecedor from './Fornecedor';
import SingleSelect from 'components/_base/SelectSingle';

import { UPDATE_STATUS, GET_STATUS_MODEL } from 'services/SolicitacaoFluxo';
import MultiSelect from 'components/_base/SelectMult';

const ContentSolicitacaoFluxoStatus = () => {
  const { showLoader } = useContext(Context);

  const paramsLocation = new URLSearchParams(useLocation().search);

  const [solicitacaoId] = useState(
    Number.parseInt(paramsLocation.get('solicitacaoId')) || 0
  );
  const [model, setModel] = useState({});
  const [showModalComment, setShowModalComment] = useState(false);
  const [resumoSolicitacaoId, setResumoSolicitacaoId] = useState(0);
  const [etapaDestinoId, setEtapaDestinoId] = useState(0);
  const [resultModel, setResultModel] = useState({});
  const [formatoArquivo, setFormatoArquivo] = useState('');

  useEffect(() => {
    (async () => {
      showLoader(true);

      const responseModel = await GET_STATUS_MODEL(solicitacaoId);
      if (
        responseModel.value.etapaViewEspecifica &&
        responseModel.value.etapaViewEspecifica.length > 0
      ) {
        history.push(
          '/solicitacao-fluxo/' +
            responseModel.value.etapaViewEspecifica +
            '?solicitacaoId=' +
            solicitacaoId
        );
        return;
      }

      setModel(responseModel.value);

      showLoader(false);
    })();
  }, []);

  function usuarioPodeAlterarSolicitacao() {
    return (
      model.usuarioComPendencia &&
      model.solicitacaoSituacao &&
      model.solicitacaoSituacao === 'R'
    );
  }

  function renderFornecedor() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return <Fornecedor solicitacaoId={model.solicitacaoId} />;
  }

  function renderResumoButton() {
    // if (usuarioPodeAlterarSolicitacao()) {
    //   return null;
    // }

    if (model.solicitacaoSituacao && model.solicitacaoSituacao === 'A')
      return (
        <div className="text-left">
          <button
            className="btn btn-primary"
            onClick={() => {
              setResumoSolicitacaoId(solicitacaoId);
            }}
          >
            Ver Solicitação
          </button>
        </div>
      );

    return null;
  }

  function renderValorPrevisto() {
    if (model?.valorPrevisto > 0)
      return (
        <div>
          <h5 className="text-right">
            <i>
              Valor Previsto:{' '}
              {model?.valorPrevisto?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </i>
          </h5>
        </div>
      );

    return null;
  }

  function renderSummary() {
    // if (usuarioPodeAlterarSolicitacao()) {
    //   return null;
    // }

    if (model.solicitacaoSituacao && model.solicitacaoSituacao === 'A')
      return null;

    return (
      <>
        <hr />
        <Summary solicitacaoId={model.solicitacaoId} />
        <hr />
      </>
    );
  }

  function renderArquivos() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return <SolicitacaoArquivos solicitacaoId={model.solicitacaoId} />;
  }

  function renderButtonAlterarSolicitacao() {
    if (usuarioPodeAlterarSolicitacao()) {
      return (
        <div className="text-left">
          <Link to={`/alterar-solicitacao/${model.solicitacaoId}`}>
            <button className="btn btn-primary">Alterar Solicitação</button>
          </Link>
        </div>
      );

      return null;
    }
  }

  function renderHistory() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <>
        <hr />
        <Historico solicitacaoId={model.solicitacaoId} />
      </>
    );
  }

  function renderButtons() {
    if (!model.etapaButtons || model.etapaButtons.length === 0) return null;

    return (
      <div className="text-center">
        {model.etapaButtons.map((etapaButton, index) => {
          return (
            <button
              key={index}
              className={'m-2 btn btn-primary btn-' + (etapaButton.tipo || '')}
              onClick={() => buttonAction(etapaButton)}
            >
              {etapaButton.texto}
            </button>
          );
        })}
      </div>
    );
  }

  function renderFormatosArquivo() {
    console.log(model);
    if (
      !model.etapaButtons ||
      model.etapaButtons.length === 0 ||
      model.etapaDescricao !== 'Arte aprovada, aguardando envio da arte final'
    )
      return null;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className="col-4 text-center">
          <p>Selecione o formato do Arquivo:</p>
          <MultiSelect
            required
            className="form-control"
            name="Formato Arquivo"
            label="Formato Arquivo"
            options={[
              { value: 'Arte Aberta', label: 'Arte Aberta' },
              { value: 'PDF p/ Impressão', label: 'PDF p/ Impressão' },
              { value: 'Arte fechada', label: 'Arte fechada' },
            ]}
            placeholder="Selecione"
            onChange={(event) => setFormatoArquivo(event)}
            value={formatoArquivo}
          />
        </div>
      </div>
    );
  }

  function buttonAction(etapaButton) {
    if (etapaButton.observacaoObrigatorio) {
      setEtapaDestinoId(etapaButton.etapaDestinoId);
      setShowModalComment(true);
    } else {
      salvar(etapaButton.etapaDestinoId);
    }
  }

  function renderModalComment() {
    if (!showModalComment) return null;

    return (
      <ModalComment
        show={true}
        enviarObservacao={enviarObservacao}
        cancelarObservacao={cancelarObservacao}
      />
    );
  }

  function renderModalSummary() {
    if (resumoSolicitacaoId === 0) return null;

    return (
      <ModalSummary
        solicitacaoId={resumoSolicitacaoId}
        onClose={() => {
          setResumoSolicitacaoId(0);
        }}
      />
    );
  }

  function enviarObservacao(observacao) {
    salvar(etapaDestinoId, observacao);
    setShowModalComment(false);
  }

  function cancelarObservacao() {
    setShowModalComment(false);
  }

  function salvar(etapaId, observacao) {
    showLoader(true);

    let postModel = {
      solicitacaoId: solicitacaoId,
      etapaDestinoId: etapaId,
      observacao: observacao,
      formatoArquivo: formatoArquivo ? formatoArquivo.join(',') : '',
    };

    (async () => {
      const response = await UPDATE_STATUS(postModel);

      //value == true => alterado com sucesso
      if (response.value) {
        setModel({});
      }

      setResultModel(response);

      showLoader(false);
    })();
  }

  function renderErrors() {
    if (!resultModel.errors || !resultModel.errors.length) return null;

    return (
      <div className="alert alert-danger">
        {resultModel.errors.map((error, index) => {
          return <span key={index}>{error}</span>;
        })}
      </div>
    );
  }

  function renderSuccess() {
    if (!resultModel.message || !resultModel.message.length) return null;

    return (
      <div className="alert alert-success">
        {resultModel.message} <a href="/pendencias">Ir para Pendencias</a>
      </div>
    );
  }

  return (
    <div className="wrapper-content-solicitacaofluxo">
      <TitlePages
        title={
          'Solicitação ' +
          solicitacaoId +
          (model.header ? ' - ' + model.header : '')
        }
      />

      <div className="content-solicitacaofluxo">
        {/* {<pre>{JSON.stringify(resultModel, null, 2)}</pre>} */}

        {renderErrors()}

        {renderSuccess()}

        {renderFornecedor()}

        {renderResumoButton()}

        {renderValorPrevisto()}

        {renderArquivos()}

        {renderFormatosArquivo()}

        {renderButtons()}

        {renderButtonAlterarSolicitacao()}

        {renderSummary()}

        {renderButtonAlterarSolicitacao()}

        {renderHistory()}

        {renderModalComment()}

        {renderModalSummary()}

        {/* {<pre className="text-left">{JSON.stringify(model, null, 2)}</pre>} */}
      </div>
    </div>
  );
};

export default ContentSolicitacaoFluxoStatus;
