/* eslint-disable */
import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Modal, Button, Row, Alert, Col } from 'react-bootstrap';
import { FaFileUpload, FaChevronLeft } from 'react-icons/fa';

import './styles.scss';
import history from 'routes/history';
import api from 'api';
import {
  GET_LIST_ARQUIVO_PLANO_ENVIO,
  GET_LIST_ENVIO_MES,
} from 'services/OnOff';
import RequestMaterialsActions from 'store/modules/request-materials/actions';
import TitlePages from 'components/_base/TitlePages';
import LoaderAction from 'store/modules/loader/actions';
import InputFileCustom from 'components/_base/InputFile';
import {
  faCaretRight,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Context } from 'context/AuthContext';
import SingleSelect from 'components/_base/SelectSingle';
import { dateFormat } from 'helpers/Date.Helper';
import getDataAtualOnOff from 'util/onoff_data_atual';

import {
  faHome,
  faFile,
  faFileAlt,
  faExclamationTriangle,
  faSearch,
  faImage,
  faFileImage,
  faFileExcel,
  faAt,
  faUser,
  faSignOutAlt,
  faQuestionCircle,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';

const OnOffMidiasEnviadas = (props) => {
  const userAuth = JSON.parse(localStorage.getItem('midias_brf_as'));
  const { showLoader } = useContext(Context);

  const [midias1, setMidias1] = useState([]);
  const [midias2, setMidias2] = useState([]);
  const [arquivos, setArquivos] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    (async () => {
      showLoader(true);

      await getMidias();

      showLoader(false);
    })();
  }, []);

  function goToRelatorio(route) {
    history.push(`/on-off-relatorios`);
  }

  function continuarEnviando(route) {
    history.push(`/adicionar-midia-on-off/`);
  }

  async function fechaPlano() {
    showLoader(true);
    // TO DO
    // todo ver aonde ta usando
    const { data } = await api.post('/OnOff/fechar-plano');

    showLoader(false);

    if (data && data.value == true) {
      history.push(`/on-off-relatorios/`);
    } else {
      setErrorMessage(
        'Não foi possível concluir os envios do mês, tente novamente!'
      );
    }
  }

  async function showModalArquivosEnvio(envioid) {
    showLoader(true);

    const data = await GET_LIST_ARQUIVO_PLANO_ENVIO(envioid);

    setArquivos(data.value);
    showLoader(false);
  }

  async function getMidias() {
    const data = await GET_LIST_ENVIO_MES();

    if (200 === data.code && data.value) {
      setMidias1(data.value.enviosPrimeiraQuinzena);
      setMidias2(data.value.enviosSegundaQuinzena);
    }
  }

  return (
    <div className="onoff-midias-enviadas">
      <h1 className="onoff-title">MÍDIAS ENVIADAS PLANO ON&OFF</h1>
      <div className="d-flex justify-content-center">
        <div className="onoff-content">
          {errorMessage && (
            <Alert
              variant="danger"
              onClose={() => setErrorMessage('')}
              dismissible
            >
              {errorMessage}
            </Alert>
          )}
          <div className="mes-info">
            UPLOADS DE MÍDIAS DO MÊS: {getDataAtualOnOff()}
          </div>
          <MidiasEnviadas />
          <NavButtons />
          <ModalArquivos />
        </div>
      </div>
    </div>
  );

  function MidiasEnviadas() {
    return (
      <div>
        {midias1 && midias1.length > 0 && (
          <div className="text-center">
            <div className="midias-label">1º Quinzena</div>
            <div className="table-responsive table-size">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Rede</th>
                    <th>Plano</th>
                    <th>Material</th>
                    <th>Subtítulo</th>
                    <th>Usuário</th>
                    <th>Data Envio</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {midias1?.map(function (envio, index) {
                    return (
                      <tr key={'1-' + index}>
                        <td>{envio.onOffRede.nome}</td>
                        <td>{envio.tipoPlano}</td>
                        <td>{envio.onOffMaterialComunicacao.nome}</td>
                        <td>{envio.subTitulo}</td>
                        <td>{envio.usuario.nome}</td>
                        <td>{dateFormat(envio.dataEnvio)}</td>
                        <td>
                          <Button
                            className="table-button"
                            variant={'primary'}
                            onClick={() => {
                              showModalArquivosEnvio(envio.onOffPlanoEnvioId);
                            }}
                          >
                            <FontAwesomeIcon icon={faImage}></FontAwesomeIcon>
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {midias2 && midias2.length > 0 && (
          <div className="text-center">
            <div className="midias-label">2º Quinzena</div>
            <div className="table-responsive table-size">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Rede</th>
                    <th>Plano</th>
                    <th>Material</th>
                    <th>Subtítulo</th>
                    <th>Usuário</th>
                    <th>Data Envio</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {midias2?.map(function (envio, index) {
                    return (
                      <tr key={'2-' + index}>
                        <td>{envio.onOffRede.nome}</td>
                        <td>{envio.tipoPlano}</td>
                        <td>{envio.onOffMaterialComunicacao.nome}</td>
                        <td>{envio.subTitulo}</td>
                        <td>{envio.usuario.nome}</td>
                        <td>{dateFormat(envio.dataEnvio)}</td>
                        <td>
                          <Button
                            className="table-button"
                            variant={'primary'}
                            onClick={() => {
                              showModalArquivosEnvio(envio.onOffPlanoEnvioId);
                            }}
                          >
                            <FontAwesomeIcon icon={faImage}></FontAwesomeIcon>
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    );
  }

  function NavButtons() {
    return (
      <div className="on-off-nav-btn">
        <Row>
          <Col lg-4="true">
            <Button
              className="onoff-button"
              disabled={''}
              onClick={goToRelatorio}
            >
              <FaChevronLeft size="28" /> VOLTAR{' '}
            </Button>
          </Col>
          <Col lg-4="true">
            <Button
              className="onoff-button"
              disabled={''}
              onClick={continuarEnviando}
            >
              <FaFileUpload size="28" /> CONTINUAR ENVIANDO MÍDIAS
            </Button>
          </Col>
          {/*<Col lg-4='true'>
            <Button className="onoff-button" disabled={""} onClick={fechaPlano}> <FaCalendarCheck size="28" />&nbsp;&nbsp; CONCLUIR ENVIO DO MÊS</Button>
          </Col>*/}
        </Row>
      </div>
    );
  }

  function ModalArquivos() {
    return (
      <Modal
        show={arquivos && arquivos.length > 0}
        onHide={() => {
          setArquivos([]);
        }}
        className="modal-historic"
      >
        <Modal.Header closeButton className="header">
          <Modal.Title>Imagens</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            {arquivos?.map(function (url, index) {
              return (
                <>
                  <img
                    key={index}
                    src={url}
                    style={{ maxHeight: '400px', maxWidth: '100%' }}
                  ></img>
                  <hr></hr>
                </>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <Button
            variant="primary"
            onClick={() => {
              setArquivos([]);
            }}
            className="button-close"
          >
            FECHAR
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};

export default OnOffMidiasEnviadas;
