/* eslint-disable */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState, useContext, useEffect } from 'react';
import { Form, Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

import { msalConfig, authProvider } from "../../authConfig";
import { AzureAD, AuthenticationState, accountInfo } from 'react-aad-msal';
import history from 'routes/history';

import { Context } from 'context/AuthContext';
import './styles.scss';

const FormSSOLogin = () => {
  const params = new URLSearchParams(useLocation().search);

  const initialState = {
    username: '',
    password: '',
    redirect: params.get('urlReturn') ? params.get('urlReturn') : '/',
  };

  const [values, setValues] = useState(initialState);
  const [authData, setAuthData] = useState(undefined);
  const [erroLogin, setErroLogin] = useState(false);
  const { handleSSOLogin, handleLogin } = useContext(Context);
  const [ ssoAuth, setSsoAuth ] = useState(null);
  const [ account, setAccount ] = useState(null);


  const userAuth = JSON.parse(localStorage.getItem('midias_brf_as'));

  useEffect(() => {
    async function innerLogin() {
      const email = account?.userName // accounts[0] && accounts[0].username;
      const authenticated = await handleSSOLogin({ email: email, redirect: values?.redirect });
      setErroLogin(!authenticated);
    }

    if (!userAuth && ssoAuth === AuthenticationState.Authenticated) {
      innerLogin();
    }
  }, [authData]);


  async function onSubmit(event, login, logout, authenticationState, error, accountInfo) {
    event.preventDefault();

    setAuthData({});
    if (authenticationState !== AuthenticationState.Authenticated) {
      localStorage.clear();
      login();
    }
    else {
      const email = accountInfo?.account?.userName; // accounts[0] && accounts[0].username;
      const authenticated = await handleSSOLogin({ email: email, redirect: values?.redirect });

      if (!authenticated)
        localStorage.clear();

      setErroLogin(!authenticated);
    }
    setAuthData({});
  }

  return (
    <div style={{ marginTop: '13px' }}>
      <label htmlFor="check">
        <FontAwesomeIcon icon={faBars} size="2x" />
      </label>

      <input type="checkbox" id="check" />

      <label htmlFor="check">
        <div className="turn-off-the-light" />
      </label>
      <Form onSubmit={(e) => onSubmit(e)}>
        <div className="form-login">
          <div>
            <label htmlFor="check">
              <FontAwesomeIcon icon={faTimes} size="1x" />
            </label>
          </div>
          <AzureAD provider={authProvider}>
            {
              ({ login, logout, authenticationState, error, accountInfo }) => {
                setSsoAuth(authenticationState);
                setAccount(accountInfo?.account);
                switch (authenticationState) {
                  case AuthenticationState.Authenticated:
                    if(!userAuth){
                      if(!authData){
                        setAuthData({});
                      }
                    }
                    return (
                      <p>
                        <span style={{color: "white"}}>Bem-vindo(a), {accountInfo?.account?.name}!&nbsp;&nbsp;</span>
                        <Button onClick={(e) => { onSubmit(e, login, logout, authenticationState, error, accountInfo) }} variant="primary" type="submit">
                          Entrar
                        </Button>
                        {erroLogin && (
                          <Badge pill variant="danger pl-2 pr-2 mt-1 mb-1">
                            <p className="mb-0 pb-0">
                              Você ainda não esta cadastrado para usar este sistema.
                            </p>
                          </Badge>
                        )}
                      </p>
                    );
                  case AuthenticationState.Unauthenticated:
                    return (
                      <div>
                        {error != null? (error.errorMessage?<p><span>{error.errorMessage}</span></p> : <p><span>Ocorreu um erro durante a autenticação. Por favor tente novamente</span></p>):<></>}
                        <p>
                          <Button onClick={(e) => { onSubmit(e, login, logout, authenticationState, error, accountInfo) }} variant="primary" type="submit">
                            Logar
                          </Button>
                        </p>
                        {erroLogin && (
                          <Badge pill variant="danger pl-2 pr-2 mt-1 mb-1">
                            <p className="mb-0 pb-0">
                              Você ainda não esta cadastrado para usar este sistema.
                            </p>
                          </Badge>
                        )}
                      </div>
                    );
                  case AuthenticationState.InProgress:
                    return (<p>Authenticating...</p>);
                }
              }
            }
          </AzureAD>
        </div>
      </Form>
    </div>
  );
};

export default FormSSOLogin;
