/* eslint-disable */
import React, { useEffect, useState } from 'react';
import actionLoader from 'store/modules/loader/actions';
import { useDispatch } from 'react-redux';

import { GET_FORNECEDOR } from 'services/SolicitacaoFluxo';

const Fornecedor = (props) => {
  let solicitacaoId = props.solicitacaoId;
  const [fornecedor, setFornecedor] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (solicitacaoId && solicitacaoId > 0) {
        dispatch(actionLoader.showLoader(true));

        const data = await GET_FORNECEDOR(props.solicitacaoId);

        setFornecedor(data.value);

        dispatch(actionLoader.showLoader(false));
      } else {
        setFornecedor({});
      }
    })();
  }, [solicitacaoId]);

  if (!(fornecedor?.fornecedorId > 0))
    return null;

  return (
    <div className="content-fornecedor mb-3">
      <h4>Fornecedor</h4>
      <table className="table table-sm">
        <tbody>
          <tr>
            <th style={{width: "1%"}} className="pr-3">Nome: </th>
            <td>{fornecedor.nome}</td>
          </tr>
          <tr>
            <th style={{width: "1%"}} className="pr-3">Email: </th>
            <td>{fornecedor.email}</td>
          </tr>
          <tr>
            <th style={{width: "1%"}} className="pr-3">Telefone: </th>
            <td>{fornecedor.telefone}</td>
          </tr>
          <tr>
            <th style={{width: "1%"}} className="pr-3">Responsável: </th>
            <td>{fornecedor.nomeResponsavel}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Fornecedor;
