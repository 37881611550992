/* eslint-disable */

import React from "react";
import { lookup } from "mime-types";

export default function FilePreview({ url, height }) {

  try {
    const mimeType = lookup(new URL(url).pathname);

    if (mimeType === false) {
      return <a href={url} target="_blank" className="btn btn-link">Download</a>
    }

    if (mimeType.includes(".ms") || mimeType.includes("openxml")) {
      return <a href={url} target="_blank"><iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`} className="mh-100 mw-100 w-auto" frameborder="0" style={{ height: `${height || 100}px` }}></iframe></a>
    }

    return <a href={url} target="_blank"><embed type={mimeType} src={url} className="mh-100 mw-100 w-auto"  style={{ height: `${height || 100}px` }}/></a>
  }

  catch (error) {
    //console.log(error);

    return null;
  }
}
