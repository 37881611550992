import api from 'api';

const PATH = '/Premissa';

const GET_LISTA_PREMISSA_POR_LOJA = async (lojaId) => {
  const { data } = await api.get(`${PATH}/get-list-por-loja/${lojaId}`);
  return data?.value || [];
};

const GET_PREMISSA_INOVACAO_POR_LOJA = async (lojaId) => {
  const premissas = await GET_LISTA_PREMISSA_POR_LOJA(lojaId);
  const inovacao = premissas.find((premissa) => {
    return premissa.premissaNome === 'Inovacao';
  });

  return inovacao;
};

const VALIDA_PREMISSA_INOVACAO = async (lojaId, qtdProdutos, qtdInovacao) => {
  const res = await api.post(`${PATH}/valida-produto-inovacao`, {
    LojaId: lojaId,
    ProdutoQtdTotal: qtdProdutos,
    ProdutoQtdInovacao: qtdInovacao,
    mensagem: '',
  });

  return res;
};

export {
  GET_LISTA_PREMISSA_POR_LOJA,
  GET_PREMISSA_INOVACAO_POR_LOJA,
  VALIDA_PREMISSA_INOVACAO,
};
