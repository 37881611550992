/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  FaAngleLeft,
  FaAngleRight,
  FaFilter,
  FaEraser,
  FaCheck,
} from 'react-icons/fa';

import TitlePages from 'components/_base/TitlePages';
import SubHeader from 'components/SubHeader';

import DatePickerCustom from 'components/_base/DatePicker';

import {
  tabs,
  passos,
  compareString,
  getDateFromStringJson,
} from '../../_constants';

import './styles.scss';

const Materiais = (props) => {
  let updateRascunho = props.updateRascunho;
  let model = props.model;
  let entities = props.entities;
  let campanhaMateriais = props.campanhaMateriais;
  let renderErrors = props.renderErrors;
  let setActiveTab = props.setActiveTab;

  let tipoMateriais = entities.tipoMateriais || [];

  const [materiais, setMateriais] = useState(entities.campanhaMateriais || []);

  let errors = (model.errors || []).filter(
    (error) => error.passo === passos.passoMateriais.nro
  );
  model.solicitacoes = model.solicitacoes || [];

  const [solicitacoes, setSolicitacoes] = useState(model.solicitacoes);
  const updateSolicitacoes = () => {
    setSolicitacoes([...model.solicitacoes]);
  };

  const [nome, setNome] = useState('');
  const [tipoMaterial, setTipoMaterial] = useState('');

  useEffect(() => {
    setSolicitacoes(model.solicitacoes);
  }, [model]);

  useEffect(() => {
    if (
      materiais.length &&
      materiais.findIndex((x) => x.campanhaId === model.campanhaId) !== -1
    )
      return;

    setMateriais(entities.campanhaMateriais || []);
  }, [entities]);

  const addRemoveItem = (event) => {
    if (event.target.checked) {
      let materiaisComunicacao = [].concat(
        ...tipoMateriais.map(
          (tipoMaterial) => tipoMaterial.materiaisComunicacao
        )
      );

      const material = materiaisComunicacao.find(
        (obj) => obj.materialComunicacaoId === parseInt(event.target.value)
      );
      const materialCampanha = materiais.find(
        (obj) => obj.materialComunicacaoId === material.materialComunicacaoId
      );

      const solicitacao = {
        materialComunicacaoId: material.materialComunicacaoId,
        materialComunicacao: material,
        solicitacaoDados: {
          layoutArteId: materialCampanha.layoutArteId,
          layoutArte: {
            layoutArteId: materialCampanha.layoutArteId,
            larguraMinima: materialCampanha.larguraMinima,
            alturaMinima: materialCampanha.alturaMinima,
          },
        },
      };

      model.solicitacoes.push(solicitacao);
      updateSolicitacoes();
    } else {
      const indexItem = solicitacoes.findIndex(
        (obj) => obj.materialComunicacaoId === parseInt(event.target.value)
      );
      model.solicitacoes.splice(indexItem, 1);
      updateSolicitacoes(solicitacoes);
    }
  };

  const filtrar = () => {
    let materiais = entities.campanhaMateriais || [];

    if (nome.length > 0)
      materiais = materiais.filter((material) =>
        compareString(material.materialComunicacaoNome, nome)
      );

    setMateriais([...materiais]);
  };

  const limpar = () => {
    setNome('');
    setMateriais(entities.campanhaMateriais || []);
  };

  const renderFiltro = () => {
    return (
      <>
        <div className="row mb-3 d-flex align-items-center ">
          <div className="col-md-2 nome-material text-right">Nome</div>
          <div className="col-md-5">
            <input
              type="text"
              className="form-control"
              value={nome}
              onChange={(event) => {
                setNome(event.target.value);
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-10 offset-lg-2 d-flex justify-content-start">
            <button
              className="btn btn-primary btn-navigation mr-3  "
              onClick={() => {
                filtrar();
              }}
            >
              <span>
                <FaFilter size={18} />
              </span>
              <span>FILTRAR</span>
            </button>

            <button
              className="btn bg-white text-primary border border-primary btn-navigation"
              onClick={() => {
                limpar();
              }}
            >
              <span>
                <FaEraser size={22} />
              </span>
              <span>LIMPAR</span>
            </button>
          </div>
        </div>
        <hr />
      </>
    );
  };

  const renderMateriais = () => {
    if (!materiais.length) return null;

    if (!tipoMateriais.length) return null;

    return (
      <div className="row">
        {materiais.map((material, index) => {
          return (
            <div key={index} className="card-material">
              {/* <label>
                  <input type="radio" checked={material.materialComunicacaoId === 0} onChange={() => {
                    model.materialComunicacaoId = material.materialComunicacaoId;

                  }} />

                </label> */}

              <div className="lista-campanha-materiais">
                <div
                  className={`header-card ${
                    solicitacoes.findIndex(
                      (solicitacao) =>
                        solicitacao.materialComunicacaoId ===
                        material.materialComunicacaoId
                    ) !== -1
                      ? 'selecionado'
                      : ''
                  }`}
                >
                  {/* {
                      solicitacoes.findIndex((solicitacao) => solicitacao.materialComunicacaoId === material.materialComunicacaoId) !== -1 &&
                      <i className="icon-check">
                        <FaCheck size={16} />
                      </i>
                    } */}
                  {material.materialComunicacaoNome}
                </div>
                <div className="body-card">
                  <input
                    type="checkbox"
                    name=""
                    id={`check-material-${material.materialComunicacaoId}`}
                    value={material.materialComunicacaoId}
                    onChange={addRemoveItem}
                    checked={
                      solicitacoes.findIndex(
                        (solicitacao) =>
                          solicitacao.materialComunicacaoId ===
                          material.materialComunicacaoId
                      ) !== -1
                    }
                  />

                  <label
                    htmlFor={`check-material-${material.materialComunicacaoId}`}
                  >
                    <img src={material.layoutArteUrl} className="img-fluid" />
                  </label>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <SubHeader title={model.loja?.razaoSocial} />

      <TitlePages title="Selecione os Materiais" />

      <div className="content-pedido-default">
        {renderErrors(errors)}

        {renderFiltro()}

        {renderMateriais()}

        <div className="row mt-5">
          <div className="col-sm-6 text-left">
            <button
              className="btn btn-primary btn-navigation"
              onClick={() => {
                updateRascunho();
                setActiveTab(tabs.tabLayoutCampanha);
              }}
            >
              <span>
                <FaAngleLeft size={26} />
              </span>
              <span>VOLTAR</span>
            </button>
          </div>

          <div className="col-sm-6 d-flex flex-row-reverse">
            <button
              className="btn btn-primary btn-navigation btn-navigation-right"
              onClick={() => {
                updateRascunho();
                setActiveTab(tabs.tabEntrega);
              }}
            >
              <span>AVANÇAR</span>
              <span>
                <FaAngleRight size={26} />
              </span>
            </button>
          </div>

          {/* <pre className="text-left">{JSON.stringify(tipoMateriais, null, 2)}</pre> */}
        </div>
      </div>
    </div>
  );
};

export default Materiais;
