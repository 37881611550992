import React from 'react';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import AdicionarMidias from '../ContentPlanoOnOff/AdicionarMidias';

const AdicionarMidiasOnOff = () => {
  return (
    <>
      <HeaderApp />
      <AdicionarMidias />
      <Bottom />
    </>
  );
};

export default AdicionarMidiasOnOff;
