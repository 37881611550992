import React, { useEffect, useState, useContext } from 'react';
import { Carousel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import { Context } from 'context/AuthContext';
import { GET_LIST_AVISO } from 'services/Aviso';
import { COUNT_PENDENCIA_USUARIO_ATUAL } from 'services/Pendencia';
import { COUNT_RASCUNHO_USUARIO_ATUAL } from 'services/Rascunho';

import rascunho from 'assets/imgs/icons/rascunho.png';
import consultar from 'assets/imgs/icons/consultar.png';
import pendencias from 'assets/imgs/icons/pendencias.png';
// import solicitarCampanha from 'assets/imgs/icons/solicitar_campanha.png';
import historico from 'assets/imgs/icons/historico.png';
import solicitarMateriais from 'assets/imgs/icons/solicitar_materiais.png';
import artesFacil from 'assets/imgs/icons/artes_facil.png';
import planoMidiasOnOff from 'assets/imgs/icons/plano_midias_on_off.png';
import relatorios from 'assets/imgs/icons/relatorios.png';
// import banco from 'assets/imgs/icons/banco.png';
import ModalAvisoPendencia from 'components/ModalAvisoPendencia';

import './styles.scss';

const ContentHome = () => {
  const [warnings, setWarnings] = useState([]);
  const { showLoader } = useContext(Context);
  const [corPendencia, setCorPendencia] = useState([]);
  const [corRascunho, setCorRascunho] = useState([]);
  const [pendenciasCount, setPendenciasCount] = useState(0);
  const userAuth = JSON.parse(localStorage.getItem('midias_brf_as'));

  const onOffUrl = process.env.REACT_APP_ON_OFF_URL;
  // console.log(process.env);

  useEffect(() => {
    async function fetchData() {
      showLoader(true);

      const corPendenciaResult = await COUNT_PENDENCIA_USUARIO_ATUAL();
      setCorPendencia(corPendenciaResult.value);
      setPendenciasCount(corPendenciaResult.value);

      const countUsuarioAtual = await COUNT_RASCUNHO_USUARIO_ATUAL();
      setCorRascunho(countUsuarioAtual.value);

      const warningsResult = await GET_LIST_AVISO();
      setWarnings(warningsResult.value);

      showLoader(false);
    }
    fetchData();
  }, []);

  return (
    <div className="wrapper-choose-the-costumer">
      {/* Carousel Banners ------------------------------------------------------------------------ */}
      <div className="warnings">
        <Carousel>
          {warnings.map((item) => {
            return (
              <Carousel.Item key={item.avisoId}>
                {item.urlRedirecionamento ? (
                  <a
                    href={item.urlRedirecionamento}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="d-block w-100"
                      src={item.urlImagem}
                      alt={item.texto}
                    />
                  </a>
                ) : (
                  <img
                    className="d-block w-100"
                    src={item.urlImagem}
                    alt={item.texto}
                  />
                )}
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>

      {/* Botoes de acao -------------------------------------------------------------------------- */}
      <div className="actions">
        {/* Linha 1 */}
        <div>
          {/* Pedido Materiais Personalizados ----------------------------------------------------- */}
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-artesfacil">
                Solicite aqui artes personalizadas para todo e qualquer
                material.
              </Tooltip>
            }
          >
            <Link to="/pedido-materiais">
              <div>
                <div>
                  <img src={solicitarMateriais} alt="pedido-materiais" />
                </div>
                <div className="btn-divider" />
                <div>Solicitar artes Personalizadas</div>
              </div>
            </Link>
          </OverlayTrigger>

          {/* Plano On-Off ------------------------------------------------------------------------ */}
          <a href={`${onOffUrl}login/${userAuth?.tokenOnOff || ''}`}>
            {/* <a href="/#"> */}
            <div>
              <div>
                <img
                  src={planoMidiasOnOff}
                  alt="Evidências Ativações AS (ON/OFF)"
                />
              </div>
              <div className="btn-divider" />
              <div>Evidências Ativações AS (ON/OFF)</div>
            </div>
          </a>

          {/* Pedido de Campanhas ----------------------------------------------------------------- */}
          {/* <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-artesfacil">
                Solicite aqui artes dos materiais disponibilizados para cada
                campanha.
              </Tooltip>
            }
          >
            <Link to="/pedido-campanha">
              <div>
                <div>
                  <img src={solicitarCampanha} alt="pedido-campanha" />
                </div>
                <div className="btn-divider" />
                <div>Solicitar Campanhas Sazonais</div>
              </div>
            </Link>
          </OverlayTrigger> */}
        </div>

        {/* Linha 2 */}
        <div>
          {/* Banco de Artes ---------------------------------------------------------------------- */}
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-bancoartes">
                Solicite aqui artes baseadas em solicitações anteriores.
              </Tooltip>
            }
          >
            <Link to="/banco-de-artes">
              <div>
                <div>
                  <img src={artesFacil} alt="Banco de Artes" />
                </div>
                <div className="btn-divider" />
                <div>Banco de Artes</div>
              </div>
            </Link>
          </OverlayTrigger>

          {/* Histórico de Pedidos ----------------------------------------------------------------- */}
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-historicopedidos">
                Consulte aqui o histórico dos pedidos.
              </Tooltip>
            }
          >
            <Link to="/historico-pedidos">
              <div>
                <div>
                  <img src={historico} alt="Histórico de Pedidos" />
                </div>
                <div className="btn-divider" />
                <div>Histórico de Pedidos</div>
              </div>
            </Link>
          </OverlayTrigger>
        </div>

        {/* Linha 3 */}
        <div>
          {/* Rascunhos --------------------------------------------------------------------------- */}
          <a href="/rascunho">
            <div style={{ backgroundColor: corRascunho > 0 ? 'red' : '' }}>
              <div>
                <img src={rascunho} alt="Rascunhos" />
              </div>
              <div className="btn-divider" />
              <div>Rascunhos</div>
            </div>
          </a>

          {/* Pendencias -------------------------------------------------------------------------- */}
          <a href="/pendencias">
            <div style={{ backgroundColor: corPendencia > 0 ? 'red' : '' }}>
              <div>
                <img src={pendencias} alt="pendencias" />
              </div>
              <div className="btn-divider" />
              <div>Pendências</div>
            </div>
          </a>
        </div>

        {/* Linha 4 */}
        <div>
          {/* Consulta ---------------------------------------------------------------------------- */}
          <a href="/consulta">
            <div>
              <div>
                <img src={consultar} alt="Consultar" />
              </div>
              <div className="btn-divider" />
              <div>Consultar</div>
            </div>
          </a>

          {/* Relatorios -------------------------------------------------------------------------- */}
          <a href="/relatorios">
            <div>
              <div>
                <img src={relatorios} alt="Relatórios" />
              </div>
              <div className="btn-divider" />
              <div>Relatórios</div>
            </div>
          </a>
        </div>
        <p>
          Em caso de dúvidas ou ocorrências sobre o webencartes brf, ligar para
          (11) 9 9811-0557 de segunda a sexta no horário das 9:00 às 18:00 ou,
          clique em atendimento ao usuário e abra um chamado que entraremos em
          contato.
        </p>
      </div>
      <ModalAvisoPendencia
        pendenciasCount={pendenciasCount}
        onClose={() => {}}
      />
    </div>
  );
};

const App = () => {
  return (
    <>
      <HeaderApp />
      <ContentHome />
      <Bottom />
    </>
  );
};

export default App;
