/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import './card-item-artes.scss';

const CardItemArtes = (props) => {
  return (
    <>
      <div className="card-artes">
        <div
          className="card-title"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span> {props.arte?.solicitacaoId}</span>
        </div>
        <div className="material">
          <span>{props.arte?.materialComunicacao}</span>
        </div>
        <div className="img-card">
          <img
            className="card-img-top center-block"
            src={props.arte?.urlArte}
            alt="imagem"
          />
        </div>
        <div className="card-body">
          <div className="row justify-content-md-center">
            <a
              className="btn btn-primary "
              onClick={() => {
                props.onClickResumo(props.arte.solicitacaoId);
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span> DETALHES</span>
            </a>
            <div className="col col-md-auto" />
            <a
              className="btn btn-primary"
              onClick={() => {
                props.handleSolicitarNovamente(props.arte.solicitacaoId);
              }}
              style={{ padding: '3px' }}
            >
              <FontAwesomeIcon icon={faCheckCircle} />
              <span>
                SOLICITAR <br /> NOVAMENTE
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardItemArtes;
