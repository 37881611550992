/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Accordion, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import TitlePages from 'components/_base/TitlePages';

import SummarySolicitacao from 'components/Summary/SummarySolicitacao';

import { Context } from 'context/AuthContext';

import api from 'api';
import { CREATE_RASCUNHO_COPIA_PEDIDO, GET_PEDIDO_ID } from 'services/Pedido';

const ContentResumoPedido = (props) => {
  const params = useParams();
  const history = useHistory();
  const { showLoader } = useContext(Context);

  const [pedido, setPedido] = useState({});

  const solicitar = () => {
    (async () => {
      showLoader(true);

      const response = await CREATE_RASCUNHO_COPIA_PEDIDO(pedido.pedidoId);

      if (response?.value) {
        let rascunho = response.value;

        history.push(`/pedido-materiais/${rascunho.rascunhoId}`);
      }

      showLoader(false);
    })();
  };

  const [ico, setIcon] = useState(false);
  const mudarIcon = () => {
    if (ico === false) {
      setIcon(true);
    } else {
      setIcon(false);
    }
  };

  useEffect(() => {
    //redireciona para home
    if (!params.pedidoId) {
      history.push('/');
    }
    //get pedido
    else {
      (async () => {
        showLoader(true);

        let response = await GET_PEDIDO_ID(params.pedidoId);
        if (response?.value) setPedido(response.value);

        showLoader(false);
      })();
    }
  }, []);

  if (!pedido.pedidoId) return null;

  return (
    <div className="wrapper-content-pedido-resumo">
      <TitlePages title={`Pedido ${pedido.pedidoId}`} />

      <div className="content-pedido-resumo">
        <div className="row">
          <div className="col-lg-12">
            <Accordion>
              {(pedido.solicitacoes || []).map((solicitacao, index) => {
                return (
                  <div key={index} className="col-lg-12 resumo mb-4">
                    <div className="resumo-header">
                      <Accordion.Toggle
                        onClick={mudarIcon}
                        as={Card.Header}
                        eventKey={`${index}`}
                      >
                        Solicitação {solicitacao.solicitacaoId} -{' '}
                        {solicitacao.materialComunicacao.nome}
                        <div className="float-right">
                          <FontAwesomeIcon
                            id="icone"
                            icon={ico !== true ? faAngleDown : faAngleUp}
                          ></FontAwesomeIcon>
                        </div>
                      </Accordion.Toggle>
                    </div>
                    <Accordion.Collapse eventKey={`${index}`}>
                      <div className="p-3">
                        <SummarySolicitacao
                          pedido={pedido}
                          solicitacao={solicitacao}
                        />
                      </div>
                    </Accordion.Collapse>
                  </div>
                );
              })}
            </Accordion>
          </div>
        </div>

        <div className="text-right">
          <button className="btn btn-primary" onClick={solicitar}>
            SOLICITAR NOVAMENTE
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContentResumoPedido;
