/* eslint-disable */
import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';

function ModalArt(props) {
    const [toggler, setToggler] = useState(false);
    

    return (
        <div className='modal-art'>
            <a style={{ cursor: ' zoom-in'}} onClick={() => setToggler(!toggler)}>
                <img src={props?.arte?.url}></img>
            </a>
            <FsLightbox
                toggler={toggler}
                sources={[
                    <img src={props?.arte?.url} style={{ maxBlockSize: '-webkit-fill-available'}}  scrolling="no"  />,
                ]}
                type="image"
            />
        </div>
    );
}

export default ModalArt;

