import React, { useContext, useEffect, useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import { Tabs, Tab } from 'react-bootstrap';
import GET_RELATORIO_GRAFICO from 'services/RelatorioGrafico';
import { Context } from 'context/AuthContext';
import {
  TabCusto,
  TabGerencial,
  TabParticipacao,
  TabProdutividade,
} from './ContentProdutividade/Tabs';
import {
  CardsCusto,
  CardsGerencial,
  CardsParticipacao,
  CardsProdutividade,
} from './ContentProdutividade/Cards';
import TabHeader from './ContentProdutividade/TabHeader';
import Filtro from './ContentProdutividade/Filtro';

import './styles.scss';

const initialFilter = {
  ano: null,
  mes: null,
  regional: null,
  canal: null,
  atividade: null,
  categoria: null,
  // programa: null,
  marca: null,
};

const Produtividade = () => {
  const { showLoader } = useContext(Context);
  const [pageLoaded, setPageLoaded] = useState(false);

  /**
   * filtro = todas as opcoes de filtro, setada apenas na primeira request
   * filtroDisp = opcoes de filtro disponiveis de acordo com a selecao
   * filtroSelecionado = opcoes de filtro selecionadas dentre as disponiveis
   */
  const [filtro, setFiltro] = useState(
    JSON.parse(JSON.stringify(initialFilter)) || null
  );
  const [filtroDisp, setFiltroDisp] = useState(
    JSON.parse(JSON.stringify(initialFilter)) || null
  );
  const [filtroSelecionado, setFiltroSelecionado] = useState(
    JSON.parse(JSON.stringify(initialFilter)) || null
  );

  const [data, setData] = useState(null);
  const [fetchDataTrigger, setFetchDataTrigger] = useState(true);

  const produtividadeRef = useRef(null);
  const custoRef = useRef(null);
  const participacaoRef = useRef(null);
  const gerencialRef = useRef(null);

  const fetchData = async () => {
    if (pageLoaded) {
      // mudando valor do filtroSelecionado para [] quando todas opcoes de filtro estao selecionadas
      const filterProperties = [
        'ano',
        'mes',
        'regional',
        'canal',
        'atividade',
        'categoria',
        // 'programa',
        'marca',
      ];
      // para cada propriedade do filtro, verifica se o filtroSelecionado é igual ao filtroDisponivel
      filterProperties.forEach((property) => {
        if (
          JSON.stringify(filtroSelecionado?.[property]?.sort()) ===
          JSON.stringify(filtroDisp?.[property]?.sort())
        ) {
          filtroSelecionado[property] = [];
        }
      });
    }
    const response = await GET_RELATORIO_GRAFICO(filtroSelecionado);
    return response;
  };

  const handleExportClick = async (tabRef, titulo) => {
    showLoader(true);
    if (tabRef.current) {
      const div = tabRef.current;
      if (div) {
        div.classList.add('export-estilo');
      }
      await html2canvas(tabRef.current).then((canvas) => {
        // Convert canvas to PNG image data URL
        const imageDataURL = canvas.toDataURL('image/png');

        // Create a link element and trigger a download
        const downloadLink = document.createElement('a');
        downloadLink.href = imageDataURL;
        const today = new Date();
        downloadLink.download = `relatorio_${titulo}-${today.getDate()}-${
          today.getMonth() + 1
        }-${today.getFullYear()}.png`;
        downloadLink.click();
      });
      div.classList.remove('export-estilo');
    }
    showLoader(false);
  };

  // useEffect inicial, roda apenas quando a pagina nao for carregada
  useEffect(async () => {
    if (!pageLoaded) {
      showLoader(true);
      await fetchData().then((res) => {
        setFiltro(res.value.filtro.filtroInicial);
        setFiltroDisp(res.value.filtro.filtroDisponivel);
        setFiltroSelecionado(res.value.filtro.filtroSelecionado);
        setData(res.value.data);
      });
      showLoader(false);
      setPageLoaded(true);
    }
  }, [pageLoaded]);

  // useEffect para atualizar os dados de acordo com o filtro selecionado
  useEffect(async () => {
    if (pageLoaded) {
      showLoader(true);
      await fetchData().then((res) => {
        setFiltroDisp(res.value.filtro.filtroDisponivel);
        setFiltroSelecionado(res.value.filtro.filtroSelecionado);
        setData(res.value.data);
      });
      showLoader(false);
    }
  }, [fetchDataTrigger]);

  return pageLoaded ? (
    <div className="wrapper-tabs-graficos">
      <Tabs className="tabs">
        <Tab eventKey="produtividade" title="Produtividade">
          <TabHeader
            title="Produtividade"
            handleExportClick={handleExportClick}
            parentRef={produtividadeRef}
            parentKey="produtividade"
          />
          <Filtro
            setFetchDataTrigger={setFetchDataTrigger}
            filtro={filtro}
            filtroDisp={filtroDisp}
            filtroSelecionado={filtroSelecionado}
            setFiltroSelecionado={setFiltroSelecionado}
          />
          <div ref={produtividadeRef}>
            <CardsProdutividade data={data?.total} />
            <TabProdutividade data={data?.produtividade} />
          </div>
        </Tab>
        <Tab eventKey="custo" title="Custo/Verba">
          <TabHeader
            title="Custo/Verba"
            handleExportClick={handleExportClick}
            parentRef={custoRef}
            parentKey="custo"
          />
          <Filtro
            setFetchDataTrigger={setFetchDataTrigger}
            filtro={filtro}
            filtroDisp={filtroDisp}
            filtroSelecionado={filtroSelecionado}
            setFiltroSelecionado={setFiltroSelecionado}
          />
          <div ref={custoRef}>
            <CardsCusto data={data?.total} />
            <TabCusto data={data?.custo} />
          </div>
        </Tab>
        <Tab eventKey="participacao" title="Participação">
          <TabHeader
            title="Participação"
            handleExportClick={handleExportClick}
            parentRef={participacaoRef}
            parentKey="participacao"
          />
          <Filtro
            setFetchDataTrigger={setFetchDataTrigger}
            filtro={filtro}
            filtroDisp={filtroDisp}
            filtroSelecionado={filtroSelecionado}
            setFiltroSelecionado={setFiltroSelecionado}
            material
          />
          <div ref={participacaoRef}>
            <CardsParticipacao data={data?.total} />
            <TabParticipacao data={data?.participacao} />
          </div>
        </Tab>
        <Tab eventKey="gerencial" title="Relatório Gerencial">
          <TabHeader
            title="Relatório Gerencial"
            handleExportClick={handleExportClick}
            parentRef={gerencialRef}
            parentKey="gerencial"
          />
          <Filtro
            setFetchDataTrigger={setFetchDataTrigger}
            filtro={filtro}
            filtroDisp={filtroDisp}
            filtroSelecionado={filtroSelecionado}
            setFiltroSelecionado={setFiltroSelecionado}
            material
          />
          <div ref={gerencialRef}>
            <CardsGerencial data={data?.total} />
            <TabGerencial data={data?.gerencial} />
          </div>
        </Tab>
      </Tabs>
    </div>
  ) : null;
};

export default Produtividade;
