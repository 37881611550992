/* eslint-disable */
import api from 'api';

const PATH = '/Sso';

const GET_SSO_LOGIN = async (bodyData) => {
  const { data } = await api.post(`${PATH}/get-token-sso`, bodyData);
  return data;
};

export { GET_SSO_LOGIN };
