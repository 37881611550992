import React from 'react';

// import HeaderLogin from 'components/Header/HeaderLogin';
import HeaderSSOLogin from 'components/Header/HeaderSSOLogin';
import Bottom from 'components/Bottom';
import ContentLogin from './ContentLogin';

const Login = () => {
  return (
    <>
      <HeaderSSOLogin />
      <ContentLogin />
      <Bottom />
    </>
  );
};

export default Login;
