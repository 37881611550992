/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button, Row, InputGroup, Col } from 'react-bootstrap';
import { FaAngleLeft, FaUserCheck, FaChevronRight,FaChevronLeft } from 'react-icons/fa';

import history from 'routes/history';
import api from 'api';
import { SEARCH_LIST_REDE_POR_NOME, UPLOAD_LOGO_ONOFF } from 'services/OnOff';
import LoaderAction from 'store/modules/loader/actions';
import InputFileCustom from 'components/_base/InputFile';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Context } from 'context/AuthContext';

import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Cliente = (props) => {
  const dispatch = useDispatch();
  const { changeFormData, onChangeTab, formData } = props;

  const [clientes, setClientes] = useState([]);
  const [showAlertaLogo, setShowAlertaLogo] = useState(false);
  const { showLoader } = useContext(Context);
  const [searchString, setSearchString] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [clientSelected, setClientSelected] = useState(null);
  const [client, setClient] = useState(formData.rede);
  const [nextAtivo, setNextAtivo] = useState('true');
  const [comLogo, setComLogo] = useState(true);

  useEffect(() => {
    (async () => {
      formData.rede && setNextAtivo('');
    })();
  }, []);

  async function searchByName(event) {
    if (searchString.length <= 2) {
      return false;
    }
    showLoader(true);

    const data = await SEARCH_LIST_REDE_POR_NOME({
      Nome: searchString,
      PageSize: 2000,
    });

    if (200 === data.code) {
      setClientes(data.value.data);
      setShowModal(true);
    }

    showLoader(false);
  }

  function textChange(event) {
    let name = event.target.value;
    setSearchString(name);
  }

  const hideModal = () => {
    setShowModal(false);
    if (clientSelected != null && clientSelected >= 0) {
      setClient(clientes[clientSelected]);
      setSearchString(clientes[clientSelected]?.nome);
      setNextAtivo('');
      const urlLogo = clientes[clientSelected]?.urlLogo;
      //let temLogo = urlLogo == null || urlLogo == '' ? false : true;
      //setComLogo(temLogo);
    }
  };

  const selectClient = (index) => (event) => {
    setClientSelected(index);
  };

  const setLogoCheckClick = (event) => {
    setComLogo(event.target.value === 'true');
  };

  const uploadLogo = async (event) => {
    event.preventDefault();

    dispatch(LoaderAction.showLoader(true));
    const formData = new FormData();

    formData.append('onOffRedeId', client.onOffRedeId);
    formData.append('file', event.target.files[0]);

    const data = await UPLOAD_LOGO_ONOFF(formData);

    setClient({ ...client, urlLogo: data.value });

    setShowAlertaLogo(false);

    dispatch(LoaderAction.showLoader(false));
  };

  function goToPreviousPage() {
    history.push('/on-off-relatorios');
  }

  function goToNextPage(route) {
    if (!client.urlLogo)
    {
      setShowAlertaLogo(true);
    }else{
      changeFormData({ rede: client, onOffRedeId: client.onOffRedeId });
      onChangeTab('upload');
    }
  }

  function ClienteInfo() {
    let info = client;
    return (
      <Row>
        <Col className="col-lg-6 onoff-info">
          <div>Rede: {info?.nome}</div>
          <div>Bandeira: {info?.bandeira}</div>
          <div>
            Regional:{' '}
            {info &&
              info.regional.regionalSuperior &&
              info?.regional?.regionalSuperior.nome}
          </div>
          <div>Filial: {info?.regional?.nome}</div>
          <div>Canal: {info?.canal?.canalSuperior?.nome}</div>
          <div>Subcanal: {info?.canal?.nome}</div>
        </Col>
        <Col className="col-lg-6 onoff-info">
          <LogoInfo />
        </Col>
      </Row>
    );
  }

  function LogoInfo() {
    return (
      <div className="set-settings-brand">
        <div style={{ opacity: client.onOffRedeId ? 1 : 0 }}>
          {/*<input
            type="radio"
            id="set-brand"
            name="with-brand"
            onClick={setLogoCheckClick}
            value={true}
            checked={comLogo}
          />
          <label htmlFor="set-brand">&nbsp;Com Logo</label>
          &nbsp;&nbsp;&nbsp;
          <input
            type="radio"
            id="set-no-brand"
            name="with-brand"
            onClick={setLogoCheckClick}
            value={false}
            checked={!comLogo}
          />
          <label htmlFor="set-no-brand">&nbsp;Sem Logo</label>*/}
          <label htmlFor="set-no-brand">&nbsp;Logo do Cliente:</label>
          {comLogo ? (
            <div>
              {client.urlLogo && (
                <img
                  className="img-logo"
                  src={client.urlLogo ? client.urlLogo : ''}
                  alt=""
                />
              )}
              <div style={{ marginTop: '20px' }}></div>
              <InputFileCustom
                name="Logo"
                onChange={uploadLogo}
                placeholder="Escolha o arquivo"
              ></InputFileCustom>
            </div>
          ) : (
            <div>
              <div className="msg-logo-padrao">
                Esse cliente ainda não tem logo cadastrado.
              </div>
              <img className="img-logo" src={''} alt="" />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      <h1 className="onoff-title">
        <FaUserCheck /> IDENTIFIQUE O CLIENTE
      </h1>
      <div className="d-flex justify-content-center">
        <div className="onoff-content">
          <div className="filter-box">
            <Row>
            <div className="col-lg-12">
              {showAlertaLogo && <div className="alert alert-danger">Selecione o logo do cliente!</div>}
            </div>
              <div className="col-lg-12">
                <InputGroup className="mt-3 onoff">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Digite o nome da rede"
                    onChange={textChange}
                    value={searchString}
                  />
                  <InputGroup.Append>
                    <Button variant="primary" onClick={searchByName}>
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
                <label>
                  Digite ao menos 3 digitos para pesquisar o nome da rede.
                </label>
              </div>
            </Row>
          </div>

          <hr className="onoff" />

          <ClienteInfo />
          <div>
            <Button
              className="onoff-button"
              disabled={''}
              onClick={() => goToPreviousPage()}
            >
              <FaChevronLeft size="28" /> VOLTAR{' '}
            </Button>
            <Button
              className="onoff-button onoff-right"
              disabled={nextAtivo}
              onClick={goToNextPage}
            >
              AVANÇAR <FaChevronRight size="28" />
            </Button>
            <div>
              <Modal
                size="xl"
                show={showModal}
                onHide={hideModal}
                className="modal-onoff-busca-cliente"
              >
                <Modal.Header closeButton className="header">
                  <Modal.Title>Clientes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Rede</th>
                        <th>Bandeira</th>
                        <th>Regional</th>
                        <th>Filial</th>
                        <th>Canal</th>
                        <th>Subcanal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientes &&
                        clientes.map((item, index) => {
                          return (
                            <tr
                              className={
                                clientSelected === index
                                  ? 'tr-selecao-loja tr-selecionado'
                                  : 'tr-selecao-loja'
                              }
                              key={index}
                              onClick={selectClient(index)}
                            >
                              <td>{item?.nome}</td>
                              <td>{item?.bandeira}</td>
                              <td>
                                {item?.regional?.regionalSuperior &&
                                  item.regional.regionalSuperior.nome}
                              </td>
                              <td>{item?.regional?.nome}</td>
                              <td>{item?.canal?.canalSuperior?.nome}</td>
                              <td>{item?.canal?.nome}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                  <Button
                    variant="primary"
                    onClick={hideModal}
                    className="button-close"
                  >
                    CONCLUIR
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cliente;
