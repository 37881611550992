import api from 'api';

const PATH = '/Relatorio';

const GET_RELATORIO_SOLICITACOES = async (filtros) => {
  const { data } = await api.post(`${PATH}/get-relatorio-solicitacoes`, {
    params: filtros,
  });
  return data;
};

const GET_SELECT_LIST_REGIONAL = async () => {
  const { data } = await api.get(`${PATH}/get-select-list-regional`);
  return data;
};

// eslint-disable-next-line import/prefer-default-export
export { GET_RELATORIO_SOLICITACOES, GET_SELECT_LIST_REGIONAL };
