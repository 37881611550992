function getDataAtualOnOff() {
  const dataAtual = new Date();
  let diferencaMes = 0;

  if (dataAtual.getDate() <= 7) {
    diferencaMes = 1;
  }

  const dataMesAnterior = new Date(
    dataAtual.getFullYear(),
    dataAtual.getMonth() - diferencaMes,
    dataAtual.getDate()
  );

  const meses = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  return `${
    meses[dataMesAnterior.getMonth()]
  }/${dataMesAnterior.getFullYear()}`;
}

export default getDataAtualOnOff;
