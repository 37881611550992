/* eslint-disable */
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DatePickerCustom from 'components/_base/DatePicker';

import {
  getDateFromStringJson,
  getCampoObrigatorioClassName,
} from '../../../../PedidoMateriais/ContentPedido/_constants';

import './styles.scss';

const ModalDataVigencia = (props) => {
  const [model, setModel] = useState(props.model|| []); 
  const [minFim, setMinFim] = useState(props.dataVeiculacaoFim);
  const [minInicio, setMinInicio] = useState(props.dataVeiculacaoInicio);

  const handleClose = () => {
    props.setModel(model);
    props.closeModal();
  };

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      className="modal-solicitacaofluxo-data-vigencia"
    >
      <Modal.Header closeButton className="header">
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {model.solicitacaoComVigencia &&
            <>
              <div className="col-lg-12">
                <h4>
                  Verifique as datas de vigência da arte:
                </h4>
                <div className="row">
                  <div className="col-lg-12 mb-4">
                    <label>Data de Início da Vigência do Material</label>
                    <DatePickerCustom
                      className={getCampoObrigatorioClassName(
                        model.dataVeiculacaoInicio,
                        true
                      )}
                      selected={
                        getDateFromStringJson(
                          model.dataVeiculacaoInicio
                        ) || ''
                      }
                      minDate={minInicio}
                      onChange={(value) => {
                        model.dataVeiculacaoInicio = value;

                        if (model.dataVeiculacaoInicio) {
                          let minFim =
                            model.dataVeiculacaoInicio.addDays(
                              model.duracaoVigencia
                            );
                          if (minFim > model.dataVeiculacaoFim)
                            model.dataVeiculacaoFim = minFim;

                          setMinFim(minFim);
                        }
                        console.log(model);
                        setModel({ ...model });
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 mb-4">
                    <label>Data Final da Vigência do Materia</label>
                    <DatePickerCustom
                      className={getCampoObrigatorioClassName(
                        model.dataVeiculacaoFim,
                        true
                      )}
                      selected={
                        getDateFromStringJson(model.dataVeiculacaoFim) ||''
                      }
                      minDate={minFim}
                      onChange={(value) => {
                        model.dataVeiculacaoFim = value;
                        console.log(model);
                        setModel({ ...model });
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          }
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <Button onClick={handleClose} className="btn btn-primary">
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDataVigencia;
