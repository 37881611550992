import React from 'react';

import PageWithoutHeader from 'components/PageWithoutHeader';

import './styles.scss';

const PoliticaPrivacidade = () => {
  return (
    <PageWithoutHeader title="Política de Privacidade">
      <div className="content-politica-privacidade">
        <p>
          Na MULTIMAXI, privacidade e segurança são prioridades e nos
          comprometemos com a transparência do tratamento de dados pessoais dos
          nossos usuários/clientes. Por isso, a presente Política de Privacidade
          estabelece como é feita a coleta, uso e transferência de informações
          de clientes ou outras pessoas que acessam ou usam nossos sites,
          plataformas e aplicativos.
          <br />
          Ao utilizar nossos serviços, você entende que coletaremos e usaremos
          suas informações pessoais nas formas descritas nesta Política, sob as
          normas de Proteção de Dados (LGPD, Lei Federal 13.709/2018), das
          disposições consumeristas da Lei Federal 8078/1990 e as demais normas
          do ordenamento jurídico brasileiro aplicáveis. Dessa forma, a
          MULTIMAXI COMUNICAÇÕES EIRELLI, doravante denominada simplesmente como
          “MULTIMAXI”, inscrita no CNPJ/MF sob o nº 10.521.313/0001-49, no papel
          de Controladora de Dados, obriga-se ao disposto na presente Política
          de Privacidade. Ainda, o documento poderá ser atualizado
          periodicamente em eventual atualização normativa e portanto convidamos
          os usuários a consultar periodicamente esse presente documento.
        </p>
        <h3>1. Quais dados coletamos sobre você e para qual finalidade?</h3>
        <p>
          Nosso site coleta e utiliza alguns dados pessoais seus, de forma a
          viabilizar a prestação de serviços e aprimorar a experiência de uso.
        </p>
        <h4>1.1. Dados pessoais fornecidos pelo titular</h4>
        <ul>
          <li>
            Nome Completo para o cadastro na plataforma e eventuais contatos;
          </li>
          <li>
            Telefone de contato, para eventuais contatos sobre suas solicitações
            criadas ou entregas de materiais impressos solicitados em nossa
            plataforma ou aplicativo;
          </li>
          <li>
            E-mail para cadastro na plataforma e eventuais contato sobre as
            solicitações criadas e entregas de materiais impressos e digitais;
          </li>
          <li>
            Cargo, regional e filial na empresa para quem prestamos serviços,
            para controle dos acessos e segurança de dados da
            plataforma/aplicativo.
          </li>
        </ul>
        <h4>1.2. Dados pessoais coletados automaticamente</h4>
        <ul>
          <li>
            Não coletamos nenhum dado automaticamente, sendo utilizados apenas
            os dados fornecidos pelo usuário e/ou empresa para quem prestamos
            serviços para o cadastro de funcionários na plataforma/aplicativo.
          </li>
        </ul>
        <h3>2. Como coletamos os seus dados?</h3>
        <p>
          Nesse sentido, a coleta dos seus dados pessoais ocorre da seguinte
          forma:
        </p>
        <ul>
          <li>
            Através do pedido de cadastro da empresa para qual prestamos os
            serviços e o usuário: (I) Faz parte da corporação e precisará de
            acesso à plataforma/aplicativo para gerenciamento de solicitações;
            (II) Atualizar seus dados na plataforma após o primeiro acesso;
          </li>
        </ul>
        <h4>2.1. Consentimento</h4>
        <p>
          É a partir do seu consentimento que tratamos os seus dados pessoais. O
          consentimento é a manifestação livre, informada e inequívoca pela qual
          você autoriza a MULTIMAXI a tratar seus dados.
          <br />
          Assim, em consonância com a Lei Geral de Proteção de Dados (LGPD),
          seus dados só serão coletados, tratados e armazenados mediante prévio
          e expresso consentimento. O seu consentimento será obtido de forma
          específica para cada finalidade acima descrita, evidenciando o
          compromisso de transparência e boa-fé da MULTIMAXI para com seus
          usuários/clientes, seguindo as regulações legislativas pertinentes.
          <br />
          Ao utilizar os serviços da MULTIMAXI e fornecer seus dados pessoais,
          você está ciente e consentindo com as disposições desta Política de
          Privacidade, além de conhecer seus direitos e como exercê-los. A
          qualquer tempo e sem nenhum custo, você poderá revogar seu
          consentimento.
          <br />É importante destacar que a revogação do consentimento para o
          tratamento dos dados pode implicar a impossibilidade da performance
          adequada de alguma funcionalidade do site que dependa da operação.
          Tais consequências serão informadas previamente.
        </p>
        <h3>3. Quais são os seus direitos?</h3>
        <p>
          A MULTIMAXI assegura a seus usuários/clientes seus direitos de titular
          previstos no artigo 18 da Lei Geral de Proteção de Dados. Dessa forma,
          você pode, de maneira gratuita e a qualquer tempo:
        </p>
        <ul>
          <li>
            <b>Confirmar a existência de tratamento de dados</b>, de maneira
            simplificada ou em formato claro e completo.
          </li>
          <li>
            <b>Acessar seus dados</b>, podendo solicitá-los em uma cópia legível
            sob forma impressa ou por meio eletrônico, seguro e idôneo.
          </li>
          <li>
            <b>Corrigir seus dados</b>, ao solicitar a edição, correção ou
            atualização destes.
          </li>
          <li>
            <b>Limitar seus dados</b> quando desnecessários, excessivos ou
            tratados em desconformidade com a legislação através da
            anonimização, bloqueio ou eliminação.
          </li>
          <li>
            <b>Solicitar a portabilidade de seus dados</b>, através de um
            relatório de dados cadastrais que a (nome empresarial simplificado)
            trata a seu respeito.
          </li>
          <li>
            <b>Eliminar seus dados</b> tratados a partir de seu consentimento,
            exceto nos casos previstos em lei.
          </li>
          <li>
            <b>Revogar seu consentimento</b>, desautorizando o tratamento de
            seus dados.
          </li>
          <li>
            <b>
              Informar-se sobre a possibilidade de não fornecer seu
              consentimento
            </b>{' '}
            e sobre as consequências da negativa.
          </li>
        </ul>
        <h3>4. Como você pode exercer seus direitos de titular?</h3>
        <p>
          Para exercer seus direitos de titular, você deve entrar em contato com
          a MULTIMAXI através dos dados de contato da plataforma/aplicativo que
          você está utilizando, disponíveis na página de atendimento. De forma a
          garantir a sua correta identificação como titular dos dados pessoais
          objeto da solicitação, é possível que solicitemos documentos ou demais
          comprovações que possam comprovar sua identidade. Nessa hipótese, você
          será informado previamente.
        </p>
        <h3>5. Como e por quanto tempo seus dados serão armazenados?</h3>
        <p>
          Seus dados pessoais coletados pela MULTIMAXI serão utilizados e
          armazenados durante o tempo necessário para a prestação do serviço ou
          para que as finalidades elencadas na presente Política de Privacidade
          sejam atingidas, considerando os direitos dos titulares dos dados e
          dos controladores. De modo geral, seus dados serão mantidos enquanto a
          relação contratual entre você e a MULTIMAXI perdurar. Findado o
          período de armazenamento dos dados pessoais, estes serão excluídos de
          nossas bases de dados ou anonimizados, ressalvadas as hipóteses
          legalmente previstas no artigo 16 lei geral de proteção de dados, a
          saber:
        </p>
        <p>
          I – cumprimento de obrigação legal ou regulatória pelo controlador;
          <br />
          II – estudo por órgão de pesquisa, garantida, sempre que possível, a
          anonimização dos dados pessoais;
          <br />
          III – transferência a terceiro, desde que respeitados os requisitos de
          tratamento de dados dispostos nesta Lei; ou
          <br />
          IV – uso exclusivo do controlador, vedado seu acesso por terceiro, e
          desde que anonimizados os dados.
          <br />
          Isto é, informações pessoais sobre você que sejam imprescindíveis para
          o cumprimento de determinações legais, judiciais e administrativas
          e/ou para o exercício do direito de defesa em processos judiciais e
          administrativos serão mantidas, a despeito da exclusão dos demais
          dados.
          <br />O armazenamento de dados coletados pela MULTIMAXI reflete o
          nosso compromisso com a segurança e privacidade dos seus dados.
          Empregamos medidas e soluções técnicas de proteção aptas a garantir a
          confidencialidade, integridade e inviolabilidade dos seus dados. Além
          disso, também contamos com medidas de segurança apropriadas aos riscos
          e com controle de acesso às informações armazenadas.
        </p>
        <h3>6. O que fazemos para manter seus dados seguros?</h3>
        <p>
          Para mantermos suas informações pessoais seguras, usamos ferramentas
          físicas, eletrônicas e gerenciais orientadas para a proteção da sua
          privacidade. Aplicamos essas ferramentas levando em consideração a
          natureza dos dados pessoais coletados, o contexto e a finalidade do
          tratamento e os riscos que eventuais violações gerariam para os
          direitos e liberdades do titular dos dados coletados e tratados.
          <br />
          Entre as medidas que adotamos, destacamos as seguintes:
          <ul>
            <li>
              Apenas pessoas autorizadas têm acesso a seus dados pessoais;
            </li>
            <li>
              O acesso a seus dados pessoais é feito somente após o compromisso
              de confidencialidade;
            </li>
            <li>
              Seus dados pessoais são armazenados em ambiente seguro e idôneo.
            </li>
          </ul>
          A MULTIMAXI se compromete a adotar as melhores posturas para evitar
          incidentes de segurança. Contudo, é necessário destacar que nenhuma
          página virtual é inteiramente segura e livre de riscos. É possível
          que, apesar de todos os nossos protocolos de segurança, problemas de
          culpa exclusivamente de terceiros ocorram, como ataques cibernéticos
          de hackers, ou também em decorrência da negligência ou imprudência do
          próprio usuário/cliente.
          <br />
          Em caso de incidentes de segurança que possa gerar risco ou dano
          relevante para você ou qualquer um de nossos usuários/clientes,
          comunicaremos aos afetados e a Autoridade Nacional de Proteção de
          Dados sobre o ocorrido, em consonância com as disposições da Lei Geral
          de Proteção de Dados.
        </p>
        <h3>7. Com quem seus dados podem ser compartilhados?</h3>
        <p>
          Tendo em vista a preservação de sua privacidade, a MULTIMAXI não
          compartilhará seus dados pessoais com nenhum terceiro não autorizado.
          <br />
          Seus dados poderão ser compartilhados em casos como os abaixo
          descritos:
          <br />I – Determinação legal, requerimento, requisição ou ordem
          judicial, com autoridades judiciais, administrativas ou governamentais
          competentes.
          <br />
          II – Caso de movimentações societárias, como fusão, aquisição e
          incorporação, de forma automática
          <br />
          III – Proteção dos direitos da MULTIMAXI em qualquer tipo de conflito,
          inclusive os de teor judicial.
        </p>
        <h3>8. Cookies ou dados de navegação</h3>
        <p>
          A MULTIMAXI faz uso de Cookies, que são arquivos de texto enviados
          pela plataforma ao seu computador e que nele se armazenam, que contém
          informações relacionadas à navegação do site. Em suma, os Cookies são
          utilizados para aprimorar a experiência de uso.
          <br />
          Ao acessar nosso site e consentir com o uso de Cookies, você manifesta
          conhecer e aceitar a utilização de um sistema de coleta de dados de
          navegação com o uso de Cookies em seu dispositivo.
          <br />
          Você pode, a qualquer tempo e sem nenhum custo, alterar as permissões,
          bloquear ou recusar os Cookies. Todavia, a revogação do consentimento
          de determinados Cookies pode inviabilizar o funcionamento correto de
          alguns recursos da plataforma.
          <br />
          Para gerenciar os cookies do seu navegador, basta fazê-lo diretamente
          nas configurações do navegador, na área de gestão de Cookies. Você
          pode acessar tutoriais sobre o tema diretamente nos links abaixo:
          <br />
          Se você usa o{' '}
          <a
            target="_blank"
            href="https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies"
            rel="noreferrer"
          >
            Internet Explorer
          </a>
          .<br />
          Se você usa o{' '}
          <a
            target="_blank"
            href="https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s"
            rel="noreferrer"
          >
            Firefox
          </a>
          .<br />
          Se você usa o{' '}
          <a
            target="_blank"
            href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
            rel="noreferrer"
          >
            Safari
          </a>
          .<br />
          Se você usa o{' '}
          <a
            target="_blank"
            href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&oco=1&hl=pt-BR"
            rel="noreferrer"
          >
            Google Chrome
          </a>
          .<br />
          Se você usa o{' '}
          <a
            target="_blank"
            href="https://support.microsoft.com/pt-br/help/4027947/microsoft-edge-delete-cookies"
            rel="noreferrer"
          >
            Microsoft Edge
          </a>
          .<br />
          Se você usa o{' '}
          <a
            target="_blank"
            href="https://help.opera.com/en/latest/web-preferences/#cookies"
            rel="noreferrer"
          >
            Opera
          </a>
          .
        </p>
        <h3>9. Alteração desta Política de Privacidade</h3>
        <p>
          A atual versão da Política de Privacidade foi formulada e atualizada
          pela última vez em: 24/08/2021. Reservamos o direito de modificar essa
          Política de Privacidade a qualquer tempo, principalmente em função da
          adequação a eventuais alterações feitas em nosso site ou em âmbito
          legislativo.
          <br />
          Recomendamos que você a revise com frequência.
          <br />
          Eventuais alterações entrarão em vigor a partir de sua publicação em
          nosso site e sempre lhe notificaremos acerca das mudanças ocorridas.
          <br />
          Ao utilizar nossos serviços e fornecer seus dados pessoais após tais
          modificações, você as consente.
        </p>
        <h3>10. Responsabilidade</h3>
        <p>
          A MULTIMAXI prevê a responsabilidade dos agentes que atuam nos
          processos de tratamento de dados, em conformidade com os artigos 42 ao
          45 da Lei Geral de Proteção de Dados. Nos comprometemos em manter esta
          Política de Privacidade atualizada, observando suas disposições e
          zelando por seu cumprimento. Além disso, também assumimos o
          compromisso de buscar condições técnicas e organizativas seguramente
          aptas a proteger todo o processo de tratamento de dados.
          <br />
          Caso a Autoridade Nacional de Proteção de Dados exija a adoção de
          providências em relação ao tratamento de dados realizado pela
          MULTIMAXI, comprometemo-nos a segui-las.
        </p>
        <h4>10.1 Isenção de responsabilidade</h4>
        <p>
          Conforme mencionado no Tópico 6, embora adotemos elevados padrões de
          segurança a fim de evitar incidentes, não há nenhuma página virtual
          inteiramente livre de riscos. Nesse sentido, a MULTIMAXI não se
          responsabiliza por:
          <br />I – Quaisquer consequências decorrentes da negligência,
          imprudência ou imperícia dos usuários em relação a seus dados
          individuais. Garantimos e nos responsabilizamos apenas pela segurança
          dos processos de tratamento de dados e do cumprimento das finalidades
          descritas no presente instrumento.
          <br />
          Destacamos que a responsabilidade em relação à confidencialidade dos
          dados de acesso é do usuário.
          <br />
          II – Ações maliciosas de terceiros, como ataques de hackers, exceto se
          comprovada conduta culposa ou deliberada da MULTIMAXI.
          <br />
          Destacamos que em caso de incidentes de segurança que possam gerar
          risco ou dano relevante para você ou qualquer um de nossos
          usuários/clientes, comunicaremos aos afetados e a Autoridade Nacional
          de Proteção de Dados sobre o ocorrido e cumpriremos as providências
          necessárias.
          <br />
          III – Inveracidade das informações inseridas pelo usuário/cliente nos
          registros necessários para a utilização dos serviços da MULTIMAXI;
          quaisquer consequências decorrentes de informações falsas ou inseridas
          de má-fé são de inteiramente responsabilidade do usuário/cliente.
        </p>
        <h4>11. Encarregado de Proteção de Dados</h4>
        <p>
          A MULTIMAXI disponibiliza os seguintes meios para que você possa
          entrar em contato conosco para exercer seus direitos de titular: (11)
          94361-8141. Caso tenha dúvidas sobre esta Política de Privacidade ou
          sobre os dados pessoais que tratamos, você pode entrar em contato com
          o nosso Encarregado de Proteção de Dados Pessoais, através dos
          seguintes canais:
          <br />
          Alessandra Kamimura (CPF: 395.431.418-51) –
          alessandra@multimaxi.com.br
        </p>
      </div>
    </PageWithoutHeader>
  );
};

export default PoliticaPrivacidade;
