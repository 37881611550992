import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
import SemDados from '../SemDados';

const HistogramaNaoObrigatorio = (props) => {
  const { data } = props;
  const titulo = 'Histograma de preço prod. não obrigatórios';

  if (!data || data.length < 1) {
    return <SemDados titulo={titulo} />;
  }

  const minRange = 5;
  const maxRange = 100;

  const categories = [];
  for (let i = minRange; i <= maxRange; i += 5) {
    categories.push(i);
  }

  const itensNames = data.map((item) => {
    return item.nome;
  });

  const itensQtde = data.map((item) => {
    return item.totalNaoObrigatorio;
  });

  const series = [
    {
      name: 'Total Produtos',
      data: categories.map((item) => {
        const index = itensNames.indexOf(item.toString());
        return index === -1 ? 0 : itensQtde[index];
      }),
    },
  ];

  const maxValue = Math.max(...itensQtde);

  const formatarValor = (valor) => {
    if (valor === undefined) return 'R$0,00';
    return `R$${valor.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
    })}`;
  };

  const options = {
    colors: ['#a4a4a4'],
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        hideZeroBarsWhenGrouped: false,
        horizontal: false,
        columnWidth: '100%',
        dataLabels: {
          position: 'top',
        },
        colors: {},
      },
    },
    // stroke: {
    //   colors: ['transparent'],
    //   width: 4,
    // },
    xaxis: {
      show: true,
      categories: [...categories],
      tickAmount: 10,
      tickPlacement: 'between',
      labels: {
        hideOverlappingLabels: false,
        show: true,
        offsetY: 0,
        offsetX: 9,
        style: {
          fontWeight: 600,
        },
        formatter: (value) => {
          return formatarValor(parseInt(value, 10));
        },
      },
      axisBorder: {
        show: true,
        color: '#a3a3a3',
      },
      axisTicks: {
        show: true,
        color: '#a3a3a3',
      },
    },
    yaxis: {
      max: maxValue * 1.2,
      show: true,
      showForNullSeries: true,
      axisBorder: {
        show: true,
      },
      labels: {
        show: false,
      },
      title: {
        text: 'Total de Produtos',
        offsetX: 10,
      },
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      parentHeightOffset: 5,
      toolbar: {
        show: true,
      },
    },
    dataLabels: {
      hideOverflowingLabels: false,
      textAnchor: 'middle',
      offsetX: 0,
      offsetY: -19,
      formatter: (value) => {
        return value === null ? '0' : `${value.toLocaleString('pt-BR')}`;
      },
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        colors: ['#373d3f'],
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      intersect: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return `<div class="arrow_box">
            <span>
              Range: <span id="data">${formatarValor(
                categories[dataPointIndex] - 5
              )} - ${formatarValor(categories[dataPointIndex])}</span>
            </span>
            <span>
              Total de Produtos: <span id="data">${
                itensQtde[dataPointIndex]
              }</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico" style={{ overflow: 'hidden' }}>
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <div>
        <Chart
          options={options}
          series={series}
          type="bar"
          height="250"
          // style={{
          //   width: options.xaxis.categories.length * 100,
          //   minWidth: '100%',
          // }}
        />
      </div>
    </div>
  );
};

export default HistogramaNaoObrigatorio;
