import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
import lineBreaker from 'helpers/LineBreaker';
import SemDados from '../SemDados';

const ObrigatorioEncarte = (props) => {
  const { data } = props;
  const titulo = 'ENCARTES POR PRODUTO OBRIGATÓRIO';

  if (!data || data.length < 1) {
    return <SemDados titulo={titulo} />;
  }

  const itensObrigatorio = data.map((item) => {
    return item.totalObrigatorio;
  });

  const itensNaoObrigatorio = data.map((item) => {
    return item.totalNaoObrigatorio;
  });

  const series = [
    {
      name: 'Obrigatório',
      data: itensObrigatorio,
    },
    {
      name: 'Não Obrigatório',
      data: itensNaoObrigatorio,
    },
  ];

  const options = {
    colors: ['#F39221', '#D3D3D3'],
    legend: {
      showForSingleSeries: true,
      // formatter: (seriesName) => {
      //   return [seriesName.toUpperCase()];
      // },
      show: true,
      position: 'top',
      horizontalAlign: 'left',
      floating: true,
      offsetX: 0,
      offsetY: 6,
    },
    plotOptions: {
      bar: {
        hideZeroBarsWhenGrouped: false,
        horizontal: false,
        columnWidth: '70%',
        dataLabels: {
          position: 'top',
        },
        colors: {},
      },
    },
    stroke: {
      colors: ['transparent'],
      width: 4,
    },
    xaxis: {
      position: 'bottom',
      show: true,
      categories: data.map((item) => {
        return item.nome;
      }),
      labels: {
        show: true,
        style: {
          fontWeight: 600,
        },
        formatter: (value) => {
          const lines = lineBreaker(value, 7);
          return lines;
        },
      },
      axisBorder: {
        show: true,
        color: '#a3a3a3',
      },
      axisTicks: {
        show: true,
        color: '#a3a3a3',
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 20,
      },
    },
    chart: {
      parentHeightOffset: 5,
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: true,
      },
    },
    dataLabels: {
      hideOverflowingLabels: false,
      textAnchor: 'middle',
      offsetX: 0,
      offsetY: -15,
      formatter: (value) => {
        return value === null
          ? '0'
          : `${(Math.round(value * 100) / 100).toLocaleString('pt-BR')}%`;
      },
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        colors: ['#373d3f'],
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      intersect: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return `<div class="arrow_box">
            <span>
              Mês: <span id="data">${
                options.xaxis.categories[dataPointIndex]
              }</span>
            </span>
            <span>
              Total Obrigatório: <span id="data">${itensObrigatorio[
                dataPointIndex
              ].toLocaleString('pt-BR')}</span>
            </span>
            <span>
              Total Não Obrigatório: <span id="data">${itensNaoObrigatorio[
                dataPointIndex
              ].toLocaleString('pt-BR')}</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico" style={{ overflow: 'hidden' }}>
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <div className="scrollable-horizontal">
        <Chart
          options={options}
          series={series}
          type="bar"
          height="260"
          style={{
            width: options.xaxis.categories.length * 100,
            minWidth: '100%',
          }}
        />
      </div>
    </div>
  );
};

export default ObrigatorioEncarte;
