/* eslint-disable */
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input';


import {
  getCampoObrigatorioClassName,
} from '../../../../PedidoMateriais/ContentPedido/_constants';

import './styles.scss';

const ModalTamanhos = (props) => {
  const [tamanhos, setTamanhos] = useState(props.tamanhos || []);

  const handleClose = () => {
    props.updateTamanhos(tamanhos);
    props.closeModal();
  };

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      className="modal-solicitacaofluxo-tamanhos"
    >
      <Modal.Header closeButton className="header">
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {tamanhos.map((solicitacaoTamanho, index) => {
          return (
            <div key={index} className="mb-2">
              <div
                className="col-lg-12"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0',
                  backgroundColor: '#e1e3e5',
                }}
              >
                <div className="row  m-0 mb-2">
                  <div className="col-lg-6 ">
                    <label className="mb-0">
                      Largura ({props.materialUnidadeMedida})
                    </label>
                    <CurrencyInput
                      decimalSeparator=","
                      thousandSeparator=""
                      className={`form-control ${getCampoObrigatorioClassName(
                        solicitacaoTamanho.largura,
                        true
                      )}`}
                      value={solicitacaoTamanho.largura || ''}
                      onChangeEvent={(event, maskedvalue, floatvalue) => {
                        solicitacaoTamanho.largura = floatvalue;
                        setTamanhos([...tamanhos]);
                      }}
                    />
                  </div>
                  <div className="col-lg-6 ">
                    <label className="mb-0">
                      Altura ({props.materialUnidadeMedida})
                    </label>
                    <CurrencyInput
                      decimalSeparator=","
                      thousandSeparator=""
                      className={`form-control ${getCampoObrigatorioClassName(
                        solicitacaoTamanho.altura,
                        true
                      )}`}
                      value={solicitacaoTamanho.altura || ''}
                      onChangeEvent={(event, maskedvalue, floatvalue) => {
                        solicitacaoTamanho.altura = floatvalue;
                        setTamanhos([...tamanhos]);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <Button onClick={handleClose} className="btn btn-primary">
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalTamanhos;
