import api from 'api';
import lib from 'helpers/lib';

const PATH = '/SolicitacaoFluxo';

const ADD_PENDENCIAS = async (solicitacaoId) => {
  const { data } = await api.post(`${PATH}/add-pendencias/${solicitacaoId}`);
  return data;
};
const CANCELAR_SOLICITACAO = async (solicitacaoId, params) => {
  const { data } = await api.post(`${PATH}/cancelarComMotivo/`, {
    solicitacaoId,
    motivo: params,
  });
  return data;
};
const GET_APROVAR_ARTE_MODEL = async (solicitacaoId) => {
  const { data } = await api.get(
    `${PATH}/get-aprovar-arte-model/${solicitacaoId}`
  );
  return data;
};
const GET_ENVIAR_COMPROVANTE_MODEL = async (solicitacaoId) => {
  const { data } = await api.get(
    `${PATH}/get-enviar-comprovante-model/${solicitacaoId}`
  );
  return data;
};
const GET_APROVAR_ORCAMENTO_MODEL = async (solicitacaoId) => {
  const { data } = await api.get(
    `${PATH}/get-aprovar-orcamento-model/${solicitacaoId}`
  );
  return data;
};
const GET_ENVIAR_ARTE_MODEL = async (solicitacaoId) => {
  const { data } = await api.get(
    `${PATH}/get-enviar-arte-model/${solicitacaoId}`
  );
  return data;
};
const GET_HISTORICO_MODEL = async (solicitacaoId) => {
  const { data } = await api.get(
    `${PATH}/get-historico-model/${solicitacaoId}`
  );
  return data;
};
const GET_FORNECEDOR = async (solicitacaoId) => {
  const { data } = await api.get(`${PATH}/get-fornecedor/${solicitacaoId}`);
  return data;
};
const GET_LIST_SOLICITACAO_ARQUIVO = async (solicitacaoId) => {
  const { data } = await api.get(
    `${PATH}/get-list-solicitacao-arquivo/${solicitacaoId}`
  );
  return data;
};
const GET_STATUS_MODEL = async (solicitacaoId) => {
  const { data } = await api.get(`${PATH}/get-status-model/${solicitacaoId}`);
  return data;
};
const UPDATE_STATUS = async (dataBody) => {
  const { data } = await api.post(`${PATH}/update-status`, dataBody);
  return data;
};
const UPLOAD_FILES = async (dataBody) => {
  const { data } = await api.post(`${PATH}/upload-files`, dataBody);
  return data;
};
const CONFIRM_UPLOAD = async (dataBody) => {
  const { data } = await api.post(`${PATH}/confirm-upload`, dataBody);
  return data;
};
const APROVAR_ARTE = async (jsonData) => {
  const formData = lib.jsonToFormData(jsonData);
  const { data } = await api.post(`${PATH}/aprovar-arte`, formData, {
    headers: {
      // eslint-disable-next-line no-underscore-dangle
      accept: 'text/plain',
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
};
const ENVIAR_COMPROVANTE = async (jsonData) => {
  const formData = lib.jsonToFormData(jsonData);
  const { data } = await api.post(`${PATH}/enviar-comprovante`, formData, {
    headers: {
      // eslint-disable-next-line no-underscore-dangle
      accept: 'text/plain',
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
};
const REPROVAR_ARTE = async (dataBody) => {
  const { data } = await api.post(`${PATH}/reprovar-arte`, dataBody);
  return data;
};
const GET_DEFIRNIR_ORCAMENTO_MODEL = async (solicitacaoId) => {
  const { data } = await api.get(
    `${PATH}/get-definir-orcamento-model/${solicitacaoId}`
  );
  return data;
};
const DEFIRNIR_ORCAMENTO = async (dataBody) => {
  const { data } = await api.post(`${PATH}/definir-orcamento`, dataBody);
  return data;
};
const APROVAR_ORCAMENTO = async (dataBody) => {
  const { data } = await api.post(`${PATH}/aprovar-orcamento`, dataBody);
  return data;
};
const REPROVAR_ORCAMENTO = async (dataBody) => {
  const { data } = await api.post(`${PATH}/reprovar-orcamento`, dataBody);
  return data;
};
const GET_ORCAMENTO_REPROVADO_MODEL = async (filtros) => {
  const { data } = await api.post(`${PATH}/get-orcamento-reprovado-model`, {
    params: filtros,
  });
  return data;
};
const GET_ORCAMENTO_REPROVADO_CANCELAR = async (filtros) => {
  const { data } = await api.post(
    `${PATH}/orcamento-reprovado-cancelar-solicitacao`,
    filtros
  );
  return data;
};
const RECEBER_ARTE_DIGITAL = async (filtros) => {
  const { data } = await api.post(`${PATH}/receber-arte-digital`, {
    params: filtros,
  });
  return data;
};

const GET_REVISAO_MODEL = async (solicitacaoId) => {
  const { data } = await api.get(`${PATH}/get-revisao-model/${solicitacaoId}`);
  return data;
};
const DEFIRNIR_TIPO_ARTE = async (dataBody) => {
  const { data } = await api.post(`${PATH}/definir-tipo-arte`, dataBody);
  return data;
};

export {
  UPDATE_STATUS,
  UPLOAD_FILES,
  CONFIRM_UPLOAD,
  APROVAR_ARTE,
  REPROVAR_ARTE,
  GET_DEFIRNIR_ORCAMENTO_MODEL,
  APROVAR_ORCAMENTO,
  REPROVAR_ORCAMENTO,
  GET_ORCAMENTO_REPROVADO_MODEL,
  RECEBER_ARTE_DIGITAL,
  ADD_PENDENCIAS,
  GET_APROVAR_ARTE_MODEL,
  GET_STATUS_MODEL,
  DEFIRNIR_ORCAMENTO,
  GET_APROVAR_ORCAMENTO_MODEL,
  GET_ENVIAR_ARTE_MODEL,
  GET_HISTORICO_MODEL,
  GET_LIST_SOLICITACAO_ARQUIVO,
  CANCELAR_SOLICITACAO,
  GET_ORCAMENTO_REPROVADO_CANCELAR,
  GET_FORNECEDOR,
  GET_ENVIAR_COMPROVANTE_MODEL,
  ENVIAR_COMPROVANTE,
  GET_REVISAO_MODEL,
  DEFIRNIR_TIPO_ARTE,
};
