/* eslint-disable import/prefer-default-export */
import api from 'api';

const PATH = '/Rede';

const GET_SELECT_LIST_REDE = async (id) => {
  const { data } = await api.get(`${PATH}/get-select-list`, {
    params: id,
  });
  return data;
};

export { GET_SELECT_LIST_REDE };
