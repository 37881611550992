/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Modal, Button, InputGroup } from 'react-bootstrap';
import api from 'api';
import { Context } from 'context/AuthContext';
import SingleSelect from 'components/_base/SelectSingle';
import { ADD_ASSUMIR_DIAGRAMACAO, GET_LIST_DEMANDA } from 'services/Demanda';
import './styles.scss';

const ContentDemandas = () => {
  const [demandas, setDemandas] = useState([]);
  const { getMaterialComunicacaoSelectList, showLoader } = useContext(Context);
  const [materialComunicacaoId, setMaterialComunicacaoId] = useState(0);
  const [materialList, setMaterialList] = useState([]);
  const [solicitacaoId, setSolicitacaoId] = useState(0);
  const maxResults = 2000;

  const [modal, setModal] = useState({
    ativo: false,
    demandaId: null, // Numero da solicitacao da demanda
  });

  useEffect(() => {
    (async () => {
      setMaterialList(await getMaterialComunicacaoSelectList());
    })();
  }, []);

  useEffect(() => {
    (async () => {
      showLoader(true);

      const params = {
        PageSize: 2000,
      };
      const data = await GET_LIST_DEMANDA(params);

      setDemandas(data.value);

      showLoader(false);
    })();
  }, []);

  function FilterBox() {
    return (
      <div className="filter-box">
        <Row>
          <div className="col-lg-3 ">
            <InputGroup className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Buscar por número"
                onChange={solicitacaoIdOnChange}
              />
              <InputGroup.Append>
                <Button variant="primary" onClick={searchBySolicitacaoId}>
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
          <div className="col-lg-4"></div>
          <label className="col-sm-2 col-form-label numero" htmlFor="">
            Material de Comunicação
          </label>
          <div className="col-lg-3 ">
            <SingleSelect
              isClearable={true}
              className="form-control "
              onChange={searchByMaterialComunicacao}
              options={materialList}
              value={materialComunicacaoId}
            />
          </div>
        </Row>
      </div>
    );
  }

  function ModalAssumirPendencia() {
    return (
      <Modal
        show={modal.ativo}
        onHide={() => setModal({ ativo: !modal.ativo, demandaId: null })}
        className="modal-discard-draft"
      >
        <Modal.Body>
          <h3>
            Tem certeza que deseja assumir a demanda da solicitacção n°{' '}
            {modal.demandaId} ?
          </h3>
          <p>
            Ao clicar em "Assumir", uma pendência será criada para você.
            Deseja continuar?
          </p>
          <Row className="buttons">
            <Col className="text-center">
              <Button
                variant="secondary"
                onClick={() =>
                  setModal({ ativo: !modal.ativo, pendenciaId: null })
                }
                className="button"
              >
                Cancelar
              </Button>
            </Col>
            <Col className="text-center">
              <Button
                variant="danger"
                onClick={() => pegarDemandaClick(modal.demandaId)}
                className="button"
              >
                Assumir
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  async function pegarDemandaClick(solicitacaoId) {
    showLoader(true);

    setModal({ ativo: false, demandaId: null });

    const params = {
      usuarioId: 0,
      solicitacaoId: solicitacaoId,
    };
    const data = await ADD_ASSUMIR_DIAGRAMACAO(params);

    if (200 === data.code) {
      const data = await GET_LIST_DEMANDA({});
      setDemandas(data.value);
    }

    showLoader(false);
  }

  function solicitacaoIdOnChange(event) {
    setSolicitacaoId(event.target.value);
  }

  function searchByMaterialComunicacao(value) {
    setMaterialComunicacaoId(value);

    getDemandas(solicitacaoId, value);
  }

  function searchBySolicitacaoId() {
    getDemandas(solicitacaoId, materialComunicacaoId);
  }

  async function getDemandas(solicitacaoId, materialComunicacaoId) {
    showLoader(true);

    const params = {
      PageSize: maxResults,
      SolicitacaoId: solicitacaoId,
      MaterialComunicacaoIds:
        materialComunicacaoId > 0 && materialComunicacaoId,
    };
    const data = await GET_LIST_DEMANDA(params);

    setDemandas(data.value);
    showLoader(false);
  }

  return (
    <div>
      <div className="filter-box">
        <Row>
          <div className="col-lg-3 ">
            <InputGroup className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Buscar por número"
                onChange={solicitacaoIdOnChange}
              />
              <InputGroup.Append>
                <Button variant="primary" onClick={searchBySolicitacaoId}>
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
          <div className="col-lg-4"></div>
          <label className="col-sm-2 col-form-label numero" htmlFor="">
            Material de Comunicação
          </label>
          <div className="col-lg-3 ">
            <SingleSelect
              isClearable={true}
              className="form-control "
              onChange={searchByMaterialComunicacao}
              options={materialList}
              value={materialComunicacaoId}
            />
          </div>
        </Row>
      </div>

      <div className="table-query ">
        <div className="table-responsive">
          <table className="table  table-stripped">
            <thead>
              <tr>
                <th>Nro.</th>
                <th>Regional</th>
                <th>Material</th>
                <th>Cliente</th>
                <th>Data limite</th>
                <th>Data Criação</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {demandas?.data?.map((demanda, indexDemandas) => {
                return (
                  <tr
                    key={indexDemandas}
                    className={demanda.alert ? 'alerta' : ''}
                  >
                    <td className="monospace">{demanda.solicitacaoId}</td>
                    <td>{demanda?.regional}</td>
                    <td>{demanda?.materialComunicacao}</td>
                    <td>{demanda?.razaoSocial}</td>
                    <td className="monospace">{demanda?.dataLimiteStr}</td>
                    <td className="monospace">{demanda?.dataCriacaoStr}</td>
                    <td>
                      <Button
                        className="table-button button-action"
                        href={
                          'solicitacao-fluxo/status?solicitacaoId=' +
                          demanda.solicitacaoId
                        }
                      >
                        Visualizar
                      </Button>
                      <Button
                        className="table-button button-action"
                        onClick={() =>
                          setModal({
                            ativo: true,
                            demandaId: demanda.solicitacaoId,
                          })
                        }
                      >
                        Pegar Solicitação
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {ModalAssumirPendencia()}
    </div>
  );
};

export default ContentDemandas;
