/* eslint-disable */
import React, { useState, useEffect, useContext  } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Switch from 'react-switch';
import CurrencyInput from 'react-currency-input';
import Select from 'react-select';
import BuscaProdutos from '../../../../PedidoMateriais/ContentPedido/Solicitacoes/Solicitacao/Produtos/BuscaProdutos';
import ProdutosEspecificos from '../../../../PedidoMateriais/ContentPedido/Solicitacoes/Solicitacao/Produtos/ProdutosEspecificos';
import { Context } from 'context/AuthContext';

import { getCampoObrigatorioClassName } from '../../../../PedidoMateriais/ContentPedido/_constants';

import {
  GET_SOLICITACAO_PRODUTO,
  GET_TAB_PRODUTOS,
  GET_LAYOUT_PRODUTOS_OBRIGATORIOS,
  GET_LAYOUT_PRODUTOS_ESPECIFICOS,
  GET_PRODUTOS_OBRIGATORIOS_REGIONAL_POR_LOJA
} from 'services/Solicitacao';

import { FaTimes } from 'react-icons/fa';

import './styles.scss';

const ModalProdutos = (props) => {
  const { showLoader } = useContext(Context);
  const [solicitacaoProdutos, setSolicitacaoProdutos] = useState(props.produtos || []);
  const [itensObrigatoriosAdicionados, setItensObrigatoriosAdicionados] = useState(0);
  const [regionalProdutosObrigatorios, setRegionalProdutosObrigatorios] = useState([]);
  const [selectListUnidades, setSelectListUnidades] = useState([]);
  const [layoutProdutosEspecificos, setLayoutProdutosEspecificos] = useState([]);
  const [layoutProdutosObrigatorios, setLayoutProdutosObrigatorios] = useState([]);
  const [produtosFiltro, setProdutosFiltro] = useState([]);
  const [produtoCategorias, setProdutoCategorias] = useState([]);
  const [entities, setEntities] = useState({});
  const [solicitacao, setSolicitacao] = useState({});
  const material = {
    validaProdutoObrigatorioRegional: props.validaProdutoObrigatorioRegional
  };

  const handleClose = () => {
    props.updateProdutos(solicitacaoProdutos);
    props.closeModal();
  };

  useEffect(() => {
    (async () => {
      showLoader(true);

      const resultLPO = await GET_LAYOUT_PRODUTOS_OBRIGATORIOS(props.layoutArteId);

      if (resultLPO?.value) {
        setLayoutProdutosObrigatorios(resultLPO.value);
      }

      const resultLPE = await GET_LAYOUT_PRODUTOS_ESPECIFICOS(props.layoutArteId);

      if (resultLPE?.value) {
        setLayoutProdutosEspecificos(resultLPE?.value);
      }

      const resultRegionalProdutos = await GET_PRODUTOS_OBRIGATORIOS_REGIONAL_POR_LOJA(props.lojaId);
      if (resultRegionalProdutos.value)
        setRegionalProdutosObrigatorios(resultRegionalProdutos.value);

      const result = await GET_TAB_PRODUTOS();

      if (result.value) {
        let produtos = result.value.produtos || [];
        let produtoCategorias = result.value.produtoCategorias || [];
        let unidades = result.value.unidades || [];

        setProdutosFiltro(produtos);
        setProdutoCategorias(produtoCategorias);

        let selectListUnidades = unidades.map((unidade) => {
          return {
            value: unidade,
            label: unidade,
          };
        });

        setSelectListUnidades(selectListUnidades);
      }

      showLoader(false);
    })();
  }, []);

  useEffect(() => {
      setEntities({
        regionalProdutosObrigatorios: regionalProdutosObrigatorios,
        produtos: produtosFiltro,
        produtoCategorias: produtoCategorias,
      });
      setSolicitacao({
        layoutProdutosEspecificos: layoutProdutosEspecificos
      });
      atualizaItensObrigatorios();
  }, [regionalProdutosObrigatorios,produtosFiltro, produtoCategorias, layoutProdutosEspecificos]);

  useEffect(() => {
    atualizaItensObrigatorios();
  }, [solicitacaoProdutos])

  const isObrigatorio = (produto) => {
    return (
      (
        (layoutProdutosObrigatorios || {}).produtosObrigatorios ||
        []
      ).findIndex((p) => produto.produtoId === p.produtoId) !== -1
    );
  };

  const atualizaItensObrigatorios = () =>{
    let regionalProdutosIds = [];

    if (regionalProdutosObrigatorios && regionalProdutosObrigatorios.produtos) {
      for (var i = 0; i < regionalProdutosObrigatorios.produtos.length; i++) {
        regionalProdutosIds.push(regionalProdutosObrigatorios.produtos[i].produtoId);
      }
    }

    let regionalProdutos = solicitacaoProdutos.filter(x => regionalProdutosIds.findIndex(p => p === x.produtoId) >= 0);

    setItensObrigatoriosAdicionados(regionalProdutos.length);
  }

  const removerProduto = (produto) => {
    let index = solicitacaoProdutos.findIndex(
      (sp) => sp.produtoId === produto.produtoId
    );
    if (index === -1) return;

    solicitacaoProdutos.splice(index, 1);
    setSolicitacaoProdutos([...solicitacaoProdutos]);
  };

  const getClassErrorPrecoRange = (produto) => {
    if (!produto.preco) return '';

    if (!produto.precoMinimo && !produto.precoMaximo) return '';

    let preco = parseFloat(produto.preco.toString().replace(',', '.'));

    if (preco < produto.precoMinimo) return 'produto-error';

    if (preco > produto.precoMaximo) return 'produto-error';

    return '';
  };

  const formatPreco = (preco) => {
    return 'R$ ' + preco.toFixed(2).replace('.', ',');
  };

  const sortedSolicitacaoProdutos = (lista) => {
    lista = lista.sort((produto) => (isObrigatorio(produto) ? -1 : 1));
    return lista.sort((produto) => (produto.produto.inovacao ? -1 : 1));
  };


  const adicionarProduto = (produto) => {
    if (
      solicitacaoProdutos.findIndex(
        (sp) => sp.produtoId === produto.produtoId
      ) !== -1
    )
      return;

    if (props.maximoProdutos > 0 && solicitacaoProdutos.length >= props.maximoProdutos)
      return;

    (async () => {
      showLoader(true);
      const result = await GET_SOLICITACAO_PRODUTO({
        lojaId: props.lojaId,
        produtoId: produto.produtoId,
      });

      if (!result?.value) return;

      //pra funcionar no mobile
      let solicitacaoProduto = { ...result.value };
      solicitacaoProduto.produto = result.value;
      solicitacaoProduto.inovacao = result.value.inovacao;
      //--

      setSolicitacaoProdutos(
        sortedSolicitacaoProdutos([...solicitacaoProdutos, solicitacaoProduto])
      );

      showLoader(false);
    })();
  };

  const renderFiltro = () => {
    

    if (!layoutProdutosEspecificos ||
      !layoutProdutosEspecificos.produtosEspecificos ||
      !layoutProdutosEspecificos.produtosEspecificos.length) {
      return (
        <BuscaProdutos
          material={material}
          entities={entities}
          solicitacaoProdutos={solicitacaoProdutos}
          adicionarProduto={adicionarProduto}
          requerProdutoInovacao={false}
        />
      );
    }

    return (
      <ProdutosEspecificos
        entities={entities}
        solicitacao={solicitacao}
        solicitacaoProdutos={solicitacaoProdutos}
        adicionarProduto={adicionarProduto}
        layoutProdutosEspecificos={layoutProdutosEspecificos}
        requerProdutoInovacao={false}
      />
    );
  };

  const renderSolicitacaoProdutos = () => {
    if (!solicitacaoProdutos.length) return null;

    return (
      <div className="mt-4">

        {props.maximoProdutos > 0 && solicitacaoProdutos.length >= props.maximoProdutos && (
          <div className="alert alert-warning">
            O máximo de produtos {props.maximoProdutos} foi atingido, não é possível
            adicionar mais produtos.
          </div>
        )}

        <div className="grid-solicitacao-produtos">
          {solicitacaoProdutos.map((solicitacaoProduto, index) => {
            return (
              <div key={index} className="row grid-row-produto">
                <div className="col-lg-12 p-0 text-right">
                  {isObrigatorio(solicitacaoProduto) ? (
                    <i>produto obrigatório</i>
                  ) : (
                    <FaTimes
                      onClick={() => {
                        removerProduto(solicitacaoProduto);
                      }}
                    />
                  )}
                </div>
                <div className="col-lg-2 image-container">
                  <img
                    src={solicitacaoProduto.urlImagemMiniatura}
                    className="img-fluid"
                  />
                </div>
                <div className="col-lg-10">

                  <div className="row">
                    <div className="col-lg-2">
                      <label>Código</label>
                      {solicitacaoProduto.produto.codigo}
                    </div>
                    <div className="col-lg-6">
                      <div className="row mb-2">
                        <div className="col-lg-12">
                          <label>Produto</label>
                          {solicitacaoProduto.produto.nome}
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className={`col-lg-12 ${getCampoObrigatorioClassName(
                            (
                              solicitacaoProduto.produto
                                ?.produtoDetalhamentos || []
                            ).length === 0 ||
                            (
                              solicitacaoProduto.solicitacaoProdutoDetalhamentos ||
                              []
                            ).length > 0,
                            true
                          )}`}
                        >
                          {(
                            solicitacaoProduto.produto?.produtoDetalhamentos ||
                            []
                          ).map((detalhamento, index) => {
                            return (
                              <label key={index} className="label-checkbox">
                                <input
                                  type="checkbox"
                                  checked={
                                    (
                                      solicitacaoProduto.solicitacaoProdutoDetalhamentos ||
                                      []
                                    ).findIndex(
                                      (x) =>
                                        x.produtoDetalhamentoId ===
                                        detalhamento.produtoDetalhamentoId
                                    ) !== -1
                                  }
                                  onChange={(event) => {
                                    solicitacaoProduto.solicitacaoProdutoDetalhamentos =
                                      solicitacaoProduto.solicitacaoProdutoDetalhamentos ||
                                      [];

                                    if (event.target.checked) {
                                      solicitacaoProduto.solicitacaoProdutoDetalhamentos.push(
                                        {
                                          produtoDetalhamentoId:
                                            detalhamento.produtoDetalhamentoId,
                                        }
                                      );
                                    } else {
                                      solicitacaoProduto.solicitacaoProdutoDetalhamentos =
                                        solicitacaoProduto.solicitacaoProdutoDetalhamentos.filter(
                                          (x) =>
                                            x.produtoDetalhamentoId !==
                                            detalhamento.produtoDetalhamentoId
                                        );
                                    }

                                    setSolicitacaoProdutos([
                                      ...solicitacaoProdutos,
                                    ]);
                                  }}
                                />
                                {detalhamento.descricao}
                              </label>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <label>Descrição no Encarte</label>
                      <input
                        type="text"
                        className={`form-control ${getCampoObrigatorioClassName(
                          solicitacaoProduto.descricao,
                          true
                        )}`}
                        value={solicitacaoProduto.descricao || ''}
                        onChange={(event) => {
                          solicitacaoProduto.descricao = event.target.value;
                          setSolicitacaoProdutos([...solicitacaoProdutos]);
                        }}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label>Observação</label>
                      <input
                        type="text"
                        className="form-control"
                        value={solicitacaoProduto.observacao || ''}
                        onChange={(event) => {
                          solicitacaoProduto.observacao = event.target.value;
                          setSolicitacaoProdutos([...solicitacaoProdutos]);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <label>Oferta</label>
                      <Switch
                        checked={solicitacaoProduto.oferta || false}
                        onChange={(checked) => {
                          solicitacaoProduto.oferta = checked;
                          setSolicitacaoProdutos([...solicitacaoProdutos]);
                        }}
                      />
                    </div>

                    <div className="col-lg-4">
                      <div className="row">
                        {!solicitacaoProduto.oferta ? (
                          <div className="col-lg-12">
                            <label>Preço</label>
                            <CurrencyInput
                              decimalSeparator=","
                              thousandSeparator=""
                              className={`form-control ${getCampoObrigatorioClassName(
                                solicitacaoProduto.preco,
                                true
                              )} ${getClassErrorPrecoRange(
                                solicitacaoProduto
                              )}`}
                              value={solicitacaoProduto.preco || ''}
                              onChangeEvent={(event) => {
                                solicitacaoProduto.preco = event.target.value;
                                setSolicitacaoProdutos([
                                  ...solicitacaoProdutos,
                                ]);
                              }}
                            />
                          </div>
                        ) : (
                          <>
                            <div className="col-lg-6">
                              <label>De</label>
                              <CurrencyInput
                                decimalSeparator=","
                                thousandSeparator=""
                                className={`form-control ${getCampoObrigatorioClassName(
                                  solicitacaoProduto.precoDe,
                                  true
                                )}`}
                                value={solicitacaoProduto.precoDe || ''}
                                onChangeEvent={(event) => {
                                  solicitacaoProduto.precoDe =
                                    event.target.value;
                                    setSolicitacaoProdutos([
                                    ...solicitacaoProdutos,
                                  ]);
                                }}
                              />
                            </div>

                            <div className="col-lg-6">
                              <label>Por</label>
                              <CurrencyInput
                                decimalSeparator=","
                                thousandSeparator=""
                                className={`form-control ${getCampoObrigatorioClassName(
                                  solicitacaoProduto.preco,
                                  true
                                )} ${getClassErrorPrecoRange(
                                  solicitacaoProduto
                                )}`}
                                value={solicitacaoProduto.preco || ''}
                                onChangeEvent={(event) => {
                                  solicitacaoProduto.preco = event.target.value;
                                  setSolicitacaoProdutos([
                                    ...solicitacaoProdutos,
                                  ]);
                                }}
                              />
                            </div>
                          </>
                        )}
                      </div>

                      {!solicitacaoProduto.precoMinimo &&
                        !solicitacaoProduto.precoMaximo ? null : (
                        <div className="row">
                          <div className="col-lg-12">
                            min: {formatPreco(solicitacaoProduto.precoMinimo)}{' '}
                            &mdash; max:{' '}
                            {formatPreco(solicitacaoProduto.precoMaximo)}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-lg-6">
                      <label>Unidade</label>
                      <Select
                        options={selectListUnidades}
                        isClearable={true}
                        className={getCampoObrigatorioClassName(
                          solicitacaoProduto.unidade,
                          true
                        )}
                        onChange={(item) => {
                          solicitacaoProduto.unidade = item?.value;
                          setSolicitacaoProdutos([...solicitacaoProdutos]);
                        }}
                        value={selectListUnidades.find(
                          (item) => item.value === solicitacaoProduto.unidade
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      className="modal-solicitacaofluxo-produtos"
    >
      <Modal.Header closeButton className="header">
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <h4 className="text-danger text-center">
        Quantidade de Produtos Inseridos: {solicitacaoProdutos.length}
      </h4>
      {props.validaProdutoObrigatorioRegional &&
        <>
          <h5 className="text-danger text-center">
            Quantidade Mínima de Itens Obrigatórios: {regionalProdutosObrigatorios.minimoProdutos}%
          </h5>
          <h5 className="text-danger text-center">
            Quantidade de Itens Obrigatórios adicionados: {itensObrigatoriosAdicionados == 0? 0: Math.floor((itensObrigatoriosAdicionados/solicitacaoProdutos.length)*100)}%
          </h5>
        </>
      }
      <h5 className="text-danger text-center">
        Mínimo: {props.minimoProdutos}/Máximo: {props.maximoProdutos}
      </h5>
      <hr />

      {renderFiltro()}

      <hr />
      {renderSolicitacaoProdutos()}
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <Button onClick={handleClose} className="btn btn-primary">
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalProdutos;
