/* eslint-disable */
import React, { useEffect, useState } from 'react';
// Tabs
import Cliente from './Cliente';
import Upload from './Upload';
import ResumoEnvio from './ResumoEnvio';
import './styles.scss';

import { GET_USUARIO } from 'services/Usuario';
import getDataAtualOnOff from 'util/onoff_data_atual';

const AdicionarMidias = () => {
  const [tab, setTab] = useState('cliente');
  const [formData, setFormData] = useState({
    rede: '',
    material: '',
    user: '',
    onOffRedeId: '',
    tipoPlano: '',
    mesReferencia: '',
    onOffMaterialComunicacaoId: '',
    subTitulo: '',
    veiculacao: '',
    observacao: '',
    onOffPlanoEnvioArquivos: [
      {
        urlArquivo: '',
        id: '_' + Math.random().toString(36).substr(2, 20),
      },
    ],
  });

  useEffect(() => {
    async function onLoad() {
      const userAuth = JSON.parse(localStorage.getItem('midias_brf_as'));
      const data = await GET_USUARIO(userAuth.userId);
      setFormData({
        ...formData,
        user: data.value,
        mesReferencia: getDataAtualOnOff(),
      });
    }
    onLoad();
  }, []);

  const onChangeNewTab = (nameTab) => {
    setTab(nameTab);
  };

  const onChangeFormData = (novoDado) => {
    setFormData({
      ...formData,
      ...novoDado,
    });
  };

  return (
    <>
      {tab === 'cliente' && (
        <Cliente
          onChangeTab={onChangeNewTab}
          formData={formData}
          changeFormData={onChangeFormData}
        />
      )}
      {tab === 'upload' && (
        <Upload
          onChangeTab={onChangeNewTab}
          formData={formData}
          changeFormData={onChangeFormData}
        />
      )}
      {tab === 'resumo-envio' && (
        <ResumoEnvio onChangeTab={onChangeNewTab} formData={formData} />
      )}
    </>
  );
};

export default AdicionarMidias;
