import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import TitlePages from 'components/_base/TitlePages';

import data from './constants.json';

const TutorialSistemaContent = () => {
  return (
    <>
      <TitlePages title="TUTORIAL DO SISTEMA" />
      <div className="container mt-5 mb-5">
        <div className="row">
          {data.map((value) => {
            return (
              <div className="col-md-6 mb-5">
                <div className="row" style={{ minHeight: '70px' }}>
                  <div className="col-10">
                    <h3>{value.title}</h3>
                  </div>
                  <div
                    className="col-2 text-right align-items-center"
                    style={{ color: '#2d2f93', fontSize: '28px' }}
                  >
                    <a
                      href={value.video}
                      target="_blank"
                      download
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </a>
                  </div>
                </div>

                <video width="100%" height="320" controls>
                  <source src={value.video} type="video/mp4" />
                  <track
                    kind="captions"
                    srcLang="pt-br"
                    label="portugues_brasil"
                  />
                  Seu navegador não suporta reprodução de video.
                </video>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TutorialSistemaContent;
