/* eslint-disable */
import React, { useState, useEffect } from 'react';
import api from '../../../api';

const _tableau = (props) => {

  const { url } = props;
  const [urlIframe, setUrlIframe] = useState('');

  useEffect(() => {
    (async () => {
      let result = await api.get(url);

      setUrlIframe(result.data.value);
    })();

  }, [url]);

  if (!urlIframe.length)
    return null;

  return (
    <iframe src={urlIframe}></iframe>
  )
};

export default _tableau;
