/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import _tableau from './_tableau';
import Extrato from './Extrato';
import ExtratoParcial from './ExtratoParcial';
import './styles.scss';
import api from '../../../api';

const ContentRelatorios = () => {
  const produtividade = "produtividade";
  const visaogeral = "visaogeral";
  const extrato = "extrato";
  const extratoparcial = "extratoparcial";

  const [activeTab, setActiveTab] = useState(produtividade);

  return (
    <div className="content_relatorios">
      <h1 className="relatorio-title">RELATÓRIOS</h1>
      <Tabs defaultActiveKey={produtividade} onSelect={(tab) => { setActiveTab(tab) }}>
        <Tab eventKey={produtividade} title="Dashboard" >
          <div className="text-center iframe-container">
            {activeTab === produtividade && <_tableau url="/Tableau/get-url-produtividade" />}
          </div>
        </Tab>
        <Tab eventKey={visaogeral} title="Evolução">
          <div className="text-center iframe-container">
            {activeTab === visaogeral && <_tableau url="/Tableau/get-url-visaogeral" />}
          </div>
        </Tab>
        <Tab eventKey={extrato} title="Extrato Mensal">
          <Extrato />
        </Tab>
        <Tab eventKey={extratoparcial} title="Extrato Parcial">
          <ExtratoParcial />
        </Tab>
      </Tabs>
    </div>
  );
};

export default ContentRelatorios;
