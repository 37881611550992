/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Select, { components } from 'react-select';

const groupStyles = {
  fontWeight: 'bold',
  fontSize: '23px',
  color: '#bbb',
  display: 'flex',
  marginLeft: '-7px',
  padding: '5px 0 2px 0',
};

const GroupHeading = (props) => (
  <div style={groupStyles}>
    <components.GroupHeading {...props} />
  </div>
);

// eslint-disable-next-line func-names
const setSelectedValue = function (selectList, value) {
  if (!selectList || selectList.length === 0) return false;

  if (!value) return false;

  for (let i = 0; i < selectList.length; i++) {
    const el = selectList[i];

    if (el.value && el.value === value) {
      return el;
    }

    if (el.options && el.options.length > 0) {
      const obj = setSelectedValue(el.options, value);

      if (obj) return obj;
    }
  }

  return false;
};

export const SingleSelect = ({
  isClearable,
  value,
  options,
  disabled,
  onChange,
  name,
}) => (
  <Select
    isClearable={isClearable}
    options={options}
    id={name}
    value={setSelectedValue(options, value)}
    components={{ GroupHeading }}
    styles={{
      groupHeading: (base) => ({
        ...base,
      }),
    }}
    // eslint-disable-next-line no-shadow
    onChange={(value) => onChange(value == null ? '' : value.value, name)}
    placeholder="Selecione..."
    isDisabled={disabled}
  />
);

export default SingleSelect;
