import '../styles.scss';
import React from 'react';
import SemDados from '../SemDados';

const PrecoMedioProduto = (props) => {
  const { data, dataLabels } = props;
  const titulo = 'PREÇO MÉDIO POR PRODUTO';

  if (!data || data.length < 1) {
    return <SemDados titulo={titulo} />;
  }

  const formatarValor = (valor) => {
    return valor === 0
      ? '-'
      : `R$${valor.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
        })}`;
  };

  // mapeia os regionais e retorna o custo por regional, se não houver retorna 0
  const getRegionais = (regionais, prodString) => {
    const tmpRegionais = regionais.map((item) => {
      return item.nome;
    });

    return dataLabels.map((label) => {
      const index = tmpRegionais.indexOf(label);
      if (index > -1) {
        return (
          <React.Fragment key={`irf-${label}-${prodString}`}>
            <td key={`ime-${label}-${prodString}`}>
              {formatarValor(regionais[index].media)}
            </td>
            <td key={`imd-${label}-${prodString}`}>
              {formatarValor(regionais[index].mediana)}
            </td>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment key={`irf-${label}-${prodString}`}>
          <td key={`ime-${label}-${prodString}`}>-</td>
          <td key={`imd-${label}-${prodString}`}>-</td>
        </React.Fragment>
      );
    });
  };

  return (
    <div className="grafico" style={{ overflow: 'hidden' }}>
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <div className="tabela-container">
        <table className="tabela">
          <thead>
            <tr key="rh">
              <th
                className="fixed-header fixed-col-1 fixed-index"
                style={{ width: '200px' }}
                aria-label="header"
                key="ha"
                rowSpan={2} // faz com que a celula ocupe duas linhas
              >
                Produto
              </th>
              {dataLabels.map((item) => {
                return (
                  <th
                    className="fixed-header"
                    style={{ width: '300px' }}
                    aria-label="header"
                    key={`h-${item}`}
                    colSpan={2}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
            <tr key="rh2">
              {dataLabels.map((item) => {
                return (
                  <React.Fragment key={`h2-${item}`}>
                    <th
                      className="fixed-header-2"
                      style={{ width: '150px' }}
                      aria-label="header"
                      key={`hm-${item}`}
                    >
                      Média de Preço
                    </th>
                    <th
                      className="fixed-header-2"
                      style={{ width: '100px' }}
                      aria-label="header"
                      key={`hme-${item}`}
                    >
                      Mediana de Preço
                    </th>
                  </React.Fragment>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((produto) => {
              const prodString = produto.nome;
              return (
                <tr key={`r-${prodString}`}>
                  <td className="fixed-col-1" key={`ip-${prodString}`}>
                    {prodString !== 'nulo' ? prodString : 'SEM PRODUTO'}
                  </td>
                  {getRegionais(produto.regional, prodString)}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PrecoMedioProduto;
