import React from 'react';

import Header from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import ContentChatCalled from './ContentChatCalled';

const ChatCalled = () => {
  return (
    <>
      <Header />
      <ContentChatCalled />
      <Bottom />
    </>
  );
};

export default ChatCalled;
