import api from 'api';

const PATH = '/OnOff';

const USUARIOLOGADO_ACESSA_ONOFF = async () => {
  const { data } = await api.get(`${PATH}/usuariologado-acessa-onoff`);
  return data;
};

const GET_LIST_DADOS_PPTX = async () => {
  const { data } = await api.get(`${PATH}/get-list-dados-pptx`);
  return data;
};

const DELETE_ONOFF_FILE = async (bodyData) => {
  const { data } = await api.post(`${PATH}/delete-onoff-file`, bodyData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'WWW-Authenticate': `Bearer ${
        localStorage.getItem('midias_brf_as') == null
          ? undefined
          : localStorage.getItem('midias_brf_as').jwtToken
      }`,
    },
  });
  return data;
};

const UPDATE_PLANO_ENVIO_FECHAR = async (bodyData) => {
  const { data } = await api.post(
    `${PATH}/update-plano-envio-fechar`,
    bodyData
  );
  return data;
};

const SEARCH_LIST_REDE_POR_NOME = async (filtros) => {
  const { data } = await api.get(`${PATH}/search-list-paged-rede-por-nome`, {
    params: filtros,
  });
  return data;
};

const GET_LIST_ARQUIVO_PLANO_ENVIO = async (planoEnvioId) => {
  const { data } = await api.get(
    `${PATH}/get-list-arquivo-plano-envio/${planoEnvioId}`
  );
  return data;
};

const GET_SELECT_LIST_CANAL = async () => {
  const { data } = await api.get(`${PATH}/get-select-list-canal`);
  return data;
};
const GET_PLANO_ENVIO = async (id) => {
  const { data } = await api.get(`${PATH}/get-plano-envio/${id}`);
  return data;
};

const GET_SELECT_LIST_MATERIAL_COMUNICACAO = async (plano) => {
  const { data } = await api.get(
    `${PATH}/get-select-list-material-comunicacao`,
    {
      params: plano,
    }
  );
  return data;
};

const GET_SELECT_LIST_MES = async () => {
  const { data } = await api.get(`${PATH}/get-select-list-mes`);
  return data;
};

const GET_SELECT_LIST_REGIONAL = async () => {
  const { data } = await api.get(`${PATH}/get-select-list-regional`);
  return data;
};

const GET_RELATORIO_ENVIO_BYTES = async (filtros) => {
  const { data } = await api.get(
    `${PATH}/get-relatorio-envio-em-bytes-por-filtro`,
    {
      params: filtros,
    }
  );
  return data;
};

const GET_RELATORIO_ENVIO_ARQUIVO = async (filtros) => {
  const { data } = await api.get(
    `${PATH}/get-relatorio-envio-em-arquivo-por-filtro`,
    {
      params: filtros,
    }
  );
  return data;
};

const GET_RESUMO_PLANO_ENVIO_ONOFF = async (id) => {
  const { data } = await api.get(`${PATH}/get-resumo-plano-envio-onoff/${id}`);
  return data;
};

const GET_PPTX = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-pptx`, {
    params: filtros,
  });
  return data;
};

const GET_PPTX2 = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-pptx2`, {
    params: filtros,
  });
  return data;
};

const GET_PLANO_ATUAL = async () => {
  const { data } = await api.get(`${PATH}/get-plano-atual`);
  return data;
};

const GET_LIST_ENVIO_MES = async () => {
  const { data } = await api.get(`${PATH}/get-list-envio-mes`);
  return data;
};

const ADD_PLANO_ENVIO = async (bodyData) => {
  const { data } = await api.post(`${PATH}/add-plano-envio`, bodyData);
  return data;
};

const UPDATE_PLANO_ENVIO = async (bodyData) => {
  const { data } = await api.post(`${PATH}/update-plano-envio`, bodyData);
  return data;
};

const UPLOAD_ON_OFF_FILE = async (bodyData) => {
  const { data } = await api.post(`${PATH}/upload-onoff-file`, bodyData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'WWW-Authenticate': `Bearer ${
        localStorage.getItem('midias_brf_as') == null
          ? undefined
          : localStorage.getItem('midias_brf_as').jwtToken
      }`,
    },
  });
  return data;
};

const UPLOAD_LOGO_ONOFF = async (bodyData) => {
  const { data } = await api.post(`${PATH}/upload-logo-onoff`, bodyData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'WWW-Authenticate': `Bearer ${
        localStorage.getItem('midias_brf_as') == null
          ? undefined
          : localStorage.getItem('midias_brf_as').jwtToken
      }`,
    },
  });
  return data;
};

export {
  USUARIOLOGADO_ACESSA_ONOFF,
  GET_LIST_DADOS_PPTX,
  UPLOAD_LOGO_ONOFF,
  UPDATE_PLANO_ENVIO,
  UPLOAD_ON_OFF_FILE,
  ADD_PLANO_ENVIO,
  GET_RESUMO_PLANO_ENVIO_ONOFF,
  GET_SELECT_LIST_REGIONAL,
  GET_RELATORIO_ENVIO_ARQUIVO,
  GET_RELATORIO_ENVIO_BYTES,
  GET_SELECT_LIST_MES,
  GET_SELECT_LIST_MATERIAL_COMUNICACAO,
  GET_LIST_ENVIO_MES,
  GET_SELECT_LIST_CANAL,
  GET_PLANO_ENVIO,
  GET_PPTX,
  GET_PPTX2,
  GET_PLANO_ATUAL,
  DELETE_ONOFF_FILE,
  UPDATE_PLANO_ENVIO_FECHAR,
  SEARCH_LIST_REDE_POR_NOME,
  GET_LIST_ARQUIVO_PLANO_ENVIO,
};
