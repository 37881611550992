import React from 'react';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import OnOffRelatorios from '../ContentPlanoOnOff/Relatorios';
import './styles.scss';

const PlanoOnOffRelatorios = () => {
  return (
    <>
      <HeaderApp />
      <OnOffRelatorios />
      <Bottom />
    </>
  );
};

export default PlanoOnOffRelatorios;
