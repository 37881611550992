import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';

import imageBlock from 'assets/imgs/block.png';
import './styles.scss';

const ContentAcessoRestrito = () => {
  return (
    <div className="d-flex justify-content-center align-items-center content-acesso-restrito">
      <div className="text-center p-4">
        <img src={imageBlock} alt="erro" />
        <h1>Acesso Restrito</h1>
        <h5>Você não possui permissão para acessar essa página.</h5>
        <Link to="/">
          <button type="button" className="mt-3 btn btn-primary btn-navigation">
            <span>
              <FaHome size={26} />
              <span className="ml-2">PÁGINA INICIAL</span>
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
};

const AcessoRestrito = () => {
  return (
    <>
      <HeaderApp />
      <ContentAcessoRestrito />
      <Bottom />
    </>
  );
};

export default AcessoRestrito;
